<template>
  <nav
    id="gnbContent"
    class="doc-gnb"
  >
    <h2 class="screen_out">{서비스명} 메인메뉴</h2>
    <ul class="list_gnb">
      <template
        v-for="(item, idx) in menuData.menuData"
        :key="`menu_${idx}`"
      >
        <li :class="{ on: item.pathData === $route.name.split('-')[0] }">
          <LinkBasic
            v-if="item.link"
            :text="item.name"
            :link="item.link"
            :isBlank="item.isOutlink"
            :data-tiara-action-name="`헤더_${item.name}_클릭`"
            data-tiara-action-kind="ClickHeader"
          />
          <a
            v-else
            href="javascript:;"
            rel="nosublink"
            role="button"
            :data-tiara-action-name="`헤더_${item.name}_클릭`"
            data-tiara-action-kind="ClickHeader"
            >{{ item.name
            }}<span
              v-if="item.isOutlink"
              class="screen_out"
              >새창열림</span
            ></a
          >
          <div
            v-if="item.subTabs.length"
            class="box_gnb"
          >
            <div
              v-for="(sub, subIdx) in item.subTabs"
              :key="`sub_${subIdx}`"
              class="inner_gnb"
            >
              <div
                v-for="(row, rowIdx) in sub"
                :key="`row_${rowIdx}`"
                :class="['row_gnb', { type_notitle: !row.title }]"
              >
                <strong v-if="row.title">{{ row.title }}</strong>
                <ul>
                  <li
                    v-for="(list, listIdx) in row.list"
                    :key="`list_${listIdx}`"
                  >
                    <LinkBasic
                      :text="list.name"
                      :link="list.link"
                      :isBlank="false"
                      :class="['link_gnb', { on: list.pathData === $route.name.split('-')[1] }]"
                      :data-tiara-action-name="`헤더_${list.name}_클릭`"
                      data-tiara-action-kind="ClickHeader"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </template>
    </ul>
  </nav>
</template>
<script setup>
import { useMenuData } from '/stores/store';
const menuData = useMenuData();
</script>
<style lang="scss" scoped>
.list_gnb {
  display: flex;
  justify-content: space-between;
  & > li {
    position: relative;
    height: 80px;
    & + li {
      margin-left: 40px;
    }
    & > a {
      display: inline-block;
      height: 77px;
      line-height: 80px;
      padding: 0 20px;
      color: #666;
      font-weight: 500;
      cursor: pointer;
    }
    &:hover {
      & > a {
        color: #1a1a1a;
      }
      .box_gnb {
        display: flex;
      }
    }
    &.on {
      & > a {
        color: #1a1a1a;
        font-weight: bold;
      }
    }
  }
}
.box_gnb {
  display: none;
  position: absolute;
  top: 80px;
  left: 0;
  padding: 32px;
  border: 1px solid #f0f0f0;
  background-color: #fff;
  .inner_gnb {
    box-sizing: border-box;
    font-size: 0;
    & + .inner_gnb {
      margin-left: 24px;
      padding-left: 24px;
      border-left: 1px solid #f0f0f0;
    }
  }
  .row_gnb {
    min-height: 108px;
    & + .row_gnb {
      margin-top: 20px;
    }
    li {
      & + li {
        margin-top: 9px;
      }
    }
    &.type_notitle {
      min-height: auto;
      li {
        & + li {
          margin-top: 20px;
        }
      }
    }
  }
  .link_gnb {
    display: block;
    min-width: 140px;
    font-size: 13px;
    line-height: 1.46;
    color: #666;
    &:hover {
      text-decoration: underline;
      color: #1a1a1a;
    }
    &.on {
      color: #1a1a1a;
      text-decoration: underline;
    }
  }
  strong {
    font-size: 15px;
    line-height: 1.27;
    & + ul {
      margin-top: 12px;
    }
  }
}
</style>
