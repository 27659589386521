<template>
  <header class="doc-header">
    <div class="inner-header">
      <h1 class="doc-title">
        <NuxtLink
          to="/"
          @click="refreshCurrPage('/')"
        >
          <img
            src="https://t1.kakaocdn.net/dkt_corp/service/logo_header.svg"
            alt="디케이테크인"
          />
          <span class="screen_out">홈으로 바로가기</span>
        </NuxtLink>
      </h1>
      <template v-if="isWidthStore.resolutionList.isPc2">
        <!-- PC2 헤더 -->
        <NavigationPc />
        <div class="wrap_btn type_pc">
          <button
            class="btn_search"
            @click="searchLayer.toggleSearchLayer"
          >
            <span class="screen_out">통합검색하기</span>
          </button>
          <ButtonConsult :logInfo="{ page: 'header', name: '헤더' }" />
        </div>
      </template>
      <template v-else>
        <!-- 모바일 헤더 -->
        <div class="wrap_btn type_mo">
          <button
            class="btn_search"
            @click="searchLayer.toggleSearchLayer"
          >
            <span class="screen_out">통합검색하기</span>
          </button>
          <button
            class="btn_menu"
            @click="menuData.mobileMenuToggle"
          >
            <span class="screen_out">메뉴열기</span>
          </button>
          <ButtonConsult
            v-if="isWidthStore.resolutionList.isPc"
            :logInfo="{ page: 'header', name: '헤더' }"
          />
        </div>
        <NavigationMo />
      </template>
    </div>
  </header>
</template>
<script setup>
import { useIsWidth, useMenuData, useSearchLayer } from '/stores/store';
const menuData = useMenuData();
const isWidthStore = useIsWidth();
const searchLayer = useSearchLayer();
</script>
<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: #fff;
  min-width: 320px;
}
.inner-header {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1920px;
  min-width: 1440px;
  height: 80px;
  margin: 0 auto;
  padding: 0 128px;
}
.wrap_btn.type_pc {
  .btn_search {
    margin-right: 17px;
    @include setSize(38px, 38px);
    @include backgroundImage(
      "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath d='M16.657 16.657A8 8 0 1 0 5.343 5.343a8 8 0 0 0 11.314 11.314zm0 0 3.535 3.535' stroke='%231A1A1A' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E",
      24px 24px,
      center
    );
  }
}

@media screen and (max-width: 1439px) {
  .inner-header {
    max-width: 100%;
    min-width: auto;
    padding: 0 80px;
  }
  .doc-title {
    a img {
      height: 20px;
    }
  }
  .btn_search {
    @include setSize(30px, 30px);
    @include backgroundImage(
      "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath d='M16.657 16.657A8 8 0 1 0 5.343 5.343a8 8 0 0 0 11.314 11.314zm0 0 3.535 3.535' stroke='%231A1A1A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E",
      24px 24px,
      center
    );
  }
  .btn_menu {
    margin-left: 18px;
    margin-right: 21px;
    @include setSize(30px, 30px);
    @include backgroundImage(
      "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath d='M4 5h16M8 12h12M4 19h16' stroke='%231A1A1A' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E",
      24px 24px,
      center
    );
  }
}
@media screen and (max-width: 1023px) {
  .inner-header {
    padding: 0 40px;
  }
  .btn_menu {
    margin-right: 0;
  }
}
@media screen and (max-width: 811px) {
  .inner-header {
    height: 60px;
    padding: 0 20px;
  }
}
@media screen and (max-width: 320px) {
  header {
    min-width: 280px;
  }
  .inner-header {
    padding: 0;
  }
}
</style>
