import { useToast } from '/stores/store';
const toast = useToast();
export const inquiryConfirmToast = (agree, recievedDate) => {
  const date = recievedDate.split('-');
  const setDate = (num) => {
    //날짜앞에 0 있는 경우 지우는 함수
    return num.replace(/^0+/, '');
  };
  const confirmText = agree
    ? `귀하는 디케이테크인의 광고성 정보를 ${date[0]}년 ${setDate(date[1])}월 ${setDate(date[2])}일 수신 동의하셨고, 이에 따라 수신동의 처리가 완료되었습니다.`
    : `귀하는 디케이테크인의 광고성 정보 수신을 ${setDate(date[0])}년 ${setDate(date[1])}월 ${setDate(date[2])}일 거부하셨으며, 이에 따라 수신 거부 처리가 완료되었습니다.`;
  toast.showToast(confirmText);
};
