import { get, post } from './corePublic';

/**
 * 추천검색어 관리 > 추천검색어 조회
 * @returns
 */
export const fetchCategory = (categoryCode) => {
  return get(`/category/list?categoryCode=${categoryCode}`, {});
};

export const createInquiry = (payload) => {
  return post('/inquiry/save', {
    // headers: {
    //   Authorization:
    //     'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJiaWxseS53b24iLCJhdXRoIjoiUk9MRV9JTlFVSVJZLFJPTEVfTUFOQUdFUiIsImlhdCI6MTczMzM4NjIyNywiZXhwIjoxNzMzNDcyNjI3fQ.acXUsOe8oVysi3aL1Kd9kJcS33TU1StCXvQsgXJEMXunV0n4_yBEAGimUKtgErfpoa4_wThlS2Al-ltiOL_GzA',
    //   'Content-Type': 'multipart/form-data',
    // },
    body: payload,
  });
};

export const createDownloadInquiry = (payload) => {
  return post('/inquiry/privacyInfo/save', {
    // headers: {
    // Authorization:
    //   'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJiaWxseS53b24iLCJhdXRoIjoiUk9MRV9JTlFVSVJZLFJPTEVfTUFOQUdFUiIsImlhdCI6MTczMzM4NjIyNywiZXhwIjoxNzMzNDcyNjI3fQ.acXUsOe8oVysi3aL1Kd9kJcS33TU1StCXvQsgXJEMXunV0n4_yBEAGimUKtgErfpoa4_wThlS2Al-ltiOL_GzA',
    // 'Content-Type': 'application/json',
    // },
    body: payload,
  });
};
