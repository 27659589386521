<!-- 팝업, 에러페이지내 사용됨 -->
<template>
  <button
    v-if="!props.link"
    type="button"
    :class="['btn_popup', props.style]"
    :disabled="props.isDisabled"
    @click="onClick"
  >
    {{ props.nameBtn }}
  </button>
  <a
    v-else
    :href="props.link"
    :class="['btn_popup', props.style]"
    :disabled="props.isDisabled"
  >
    {{ props.nameBtn }}
  </a>
  <!-- 사용시
  <ButtonPopup
    :nameBtn="'오늘 하루 안보기'"
    :style="'type_black'"
  /> -->
</template>
<script setup>
const emit = defineEmits(['onClickEvnet']);
const props = defineProps({
  nameBtn: {
    type: String,
    default: '',
  },
  style: {
    type: String,
    default: '',
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  link: {
    type: String,
    default: '',
  },
});

const onClick = (event) => {
  emit('onClickEvnet', event);
};
</script>
<style lang="scss" scoped>
.btn_popup {
  display: inline-block;
  min-width: 160px;
  height: 44px;
  margin: 0 4px;
  font-size: 15px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  border-radius: 4px;
  z-index: 9;
  &.type_black {
    background-color: #1a1a1a;
    color: #fff;
    &:disabled {
      background-color: #666;
      color: #999;
      cursor: default;
    }
  }
  &.type_white {
    background-color: #fff;
    color: #1a1a1a;
    outline: 1px solid #b3b3b3;
  }
  &.type_grey {
    background-color: #4c4c4c;
    color: #fff;
  }
}
@media screen and (max-width: 1023px) {
  //모바일 스타일
  .btn_popup {
    width: 100%;
    min-width: auto;
    height: 52px;
    font-size: 14px;
    font-weight: 500;
    line-height: 52px;
    border-radius: 0;
    margin: 0;
    &.type_white {
      background-color: #e5e5e5;
    }
  }
}
</style>
