<template>
  <div class="wrap_layer">
    <div class="layer_inquiry">
      <div class="head_layer">
        <h2>문의하기</h2>
      </div>
      <div class="body_layer">
        <div class="inner_body">
          <form class="form_download">
            <div class="area_form">
              <div class="item_form">
                <!-- 텍스트 길이 자르기 -->
                <InputBasic
                  :id="'name'"
                  v-model="inputValues.name.value"
                  :label="'이름'"
                  :isEssential="true"
                  :maxlength="6"
                  :placeHolder="'이름을 입력해주세요.'"
                  :isError="inputValues.name.isError"
                  @onInputEvent="valueCheck(inputValues.name, checkValidName)"
                  @onBlurEvent="valueCheck(inputValues.name, checkValidName)"
                />
              </div>
              <div class="item_form">
                <InputBasic
                  :id="'phone'"
                  v-model="inputValues.phone.value"
                  :label="'연락처'"
                  :isEssential="true"
                  :maxlength="11"
                  :placeHolder="'‘-’제외하고 숫자만 입력해주세요.'"
                  :isError="inputValues.phone.isError"
                  @onInputEvent="valueCheck(inputValues.phone, checkValidPhone)"
                  @onBlurEvent="valueCheck(inputValues.phone, checkValidPhone)"
                />
              </div>
              <div class="item_form">
                <InputBasic
                  :id="'firm'"
                  v-model="inputValues.firm.value"
                  :label="'회사(소속)'"
                  :isEssential="true"
                  :maxlength="20"
                  :placeHolder="'회사(소속)을 입력해주세요.'"
                  :isError="inputValues.firm.isError"
                  @onInputEvent="valueCheck(inputValues.firm, checkValidAboutFirm)"
                  @onBlurEvent="valueCheck(inputValues.firm, checkValidAboutFirm)"
                />
              </div>
              <div class="item_form">
                <InputBasic
                  :id="'jobRole'"
                  v-model="inputValues.jobRole.value"
                  :label="'직급'"
                  :isEssential="true"
                  :maxlength="20"
                  :placeHolder="'직급을 입력해주세요.'"
                  :isError="inputValues.jobRole.isError"
                  @onInputEvent="valueCheck(inputValues.jobRole, checkValidAboutFirm)"
                  @onBlurEvent="valueCheck(inputValues.jobRole, checkValidAboutFirm)"
                />
              </div>
              <div class="item_form">
                <SelectBasic
                  :label="'산업군'"
                  :isEssential="true"
                  :placeHolder="'산업군을 선택해 주세요.'"
                  :optList="industryOpt"
                  :optType="'industry'"
                  :isError="inputValues.industry.isError"
                  @selectItem="selectItem"
                />
              </div>
              <div class="item_form">
                <InputBasic
                  :id="'email'"
                  v-model="inputValues.email.value"
                  :label="'이메일'"
                  :isEssential="true"
                  :placeHolder="'이메일을 입력해주세요.'"
                  :isError="inputValues.email.isError"
                  @onInputEvent="valueCheck(inputValues.email, checkValidEmail)"
                  @onBlurEvent="valueCheck(inputValues.email, checkValidEmail)"
                />
              </div>
            </div>
            <div class="area_form">
              <div class="item_form">
                <SelectBasic
                  :label="'문의유형'"
                  :isEssential="true"
                  :placeHolder="'문의유형을 선택해주세요'"
                  :optList="inquiryOpt"
                  :optType="'kindInquiry'"
                  :isError="inputValues.kindInquiry.isError"
                  @selectItem="selectTypeItem"
                />
              </div>
              <div class="item_form">
                <SelectBasic
                  :hasCate="true"
                  :label="SecondarySelectData.label"
                  :isEssential="true"
                  :placeHolder="`${SecondarySelectData.placeholder}을 선택해주세요`"
                  :optList="SecondarySelectData.data"
                  :optType="'kindSecondary'"
                  :isError="inputValues.kindSecondary.isError"
                  @selectItem="selectTypeItem"
                />
              </div>
              <div class="item_form">
                <TextAreaBasic
                  :id="'detailData'"
                  v-model="inputValues.detailData.value"
                  :label="'내용'"
                  :isEssential="true"
                  :placeHolder="'문의내용을 입력해주세요.'"
                  :isError="inputValues.detailData.isError"
                  @onInputEvent="checkInputValue(inputValues.detailData)"
                  @onBlurEvent="checkInputValue(inputValues.detailData)"
                />
              </div>
              <div class="item_form">
                <InputFile
                  :id="'addedFile'"
                  :label="'첨부파일'"
                  :isEssential="false"
                  :maxFileSize="30"
                  :acceptInfo="[
                    'pdf',
                    'doc',
                    'docx',
                    'ppt',
                    'pptx',
                    'hwp',
                    'gul',
                    'jpg',
                    'png',
                    'txt',
                    'xlsx',
                    'xls',
                  ]"
                  @addFileList="setFileList"
                />
              </div>
            </div>
          </form>
          <!-- 에러 노출 케이스 분리 검토 -->
          <div class="area_error">
            <span v-if="inputValues.name.isError">· 이름이 정확한지 확인해 주세요.</span>
            <span v-if="inputValues.phone.isError">· 연락처가 정확한지 확인해 주세요.</span>
            <span v-if="inputValues.firm.isError">· 회사(소속)이 정확한지 확인해 주세요.</span>
            <span v-if="inputValues.jobRole.isError">· 직급이 정확한지 확인해 주세요.</span>
            <span v-if="inputValues.email.isError">· 이메일이 정확한지 확인해 주세요.</span>
            <span v-if="inputValues.kindInquiry.isError">· 문의유형이 정확한지 확인해 주세요.</span>
            <span v-if="inputValues.kindSecondary.isError"
              >· 비즈니스유형이 정확한지 확인해 주세요.</span
            >
            <span v-if="inputValues.detailData.isError">· 문의내용이 정확한지 확인해 주세요.</span>
          </div>
          <div class="area_agree">
            <div class="box_agree">
              <CheckboxBasic
                :id="'chkAgree1'"
                v-model="inputValues.chkAgree1.value"
                :title="'개인정보 수집·이용 동의 (필수)'"
                :style="'black'"
              />
              <p class="txt_agree">
                문의하신 내용 원인 파악 및 원활한 상담을 위하여 이름, 연락처, 회사(소속), 직급,
                산업군, 이메일, 문의내용을 수집합니다. <br />수집된 개인정보는
                <strong>5년간</strong> 보관후 파기됩니다. 위 동의를 거부할 권리가 있으며, 동의를
                거부하실 경우 문의 처리 및 결과 회신이 제한됩니다. 요구하지 않은 개인정보는 입력하지
                않도록 주의해 주세요. 더 자세한 내용에 대해서는
                <LinkBasic
                  text="개인정보처리방침"
                  link="https://policy.dktechin.com/privacy-policy"
                  :isBlank="true"
                />을 참고하시기 바랍니다.
              </p>
            </div>
            <div class="box_agree">
              <CheckboxBasic
                :id="'chkAgree2'"
                v-model="inputValues.chkAgree2.value"
                :title="'마케팅 활용 및 수신 동의(선택)'"
                :style="'black'"
              />
              <p class="txt_agree">
                회사 및 회사의 서비스와 관련된
                <strong>광고 및 정보 제공(이벤트, 서비스 안내, 생활 정보 등)</strong> 을 위하여
                이름, 연락처, 이메일을 수집합니다. 수집된 개인정보는 <strong>5년간</strong> 보관 후
                파기됩니다. 위 동의를 거부할 권리가 있으며, 동의를 거부하실 경우 광고 및 정보 제공이
                제한됩니다. 더 자세한 내용에 대해서는
                <LinkBasic
                  text="개인정보처리방침"
                  link="https://policy.dktechin.com/privacy-policy"
                  :isBlank="true"
                />을 참고하시기 바랍니다.
              </p>
            </div>
          </div>
          <div class="wrap_btn">
            <ButtonPopup
              :nameBtn="'문의하기'"
              :style="'type_black'"
              :isDisabled="isFormFilled"
              @onClickEvnet="submitInquiry"
            />
          </div>
        </div>
      </div>
      <button
        type="button"
        class="btn_close"
        @click="consultLayer.toggleConsultLayer('close')"
      >
        <span class="screen_out">팝업 닫기</span>
      </button>
    </div>
  </div>
  <div class="dimmed"></div>
</template>

<script setup>
import { fetchCategory, createInquiry } from '/api/inquiry';
import { useConsultOpen } from '/stores/store';
import { useApi } from 'daco-common-nuxt3';
const consultLayer = useConsultOpen();

useHead({
  bodyAttrs: {
    class: 'stop_scrolling',
  },
});
const businessTemplateInfo = {
  CATE000206: `* 기능문의는 아래 URL로 접수 부탁드립니다.
(URL: https://dktechin.com/inquiry/faq?service=1&page=1)\n----------------------------------------\n\n\n
 - 도입규모 : (사용자 수)\n\n\n - 도입일정 : \n\n\n- 도입희망 서비스 : (메신저 / 메일 / 전자결재 / 캘린더 / 화상회의(&웨비나) / 워크보드 / 확장서비스 및 BOT 연동)\n\n\n- (선택) 필요한 워크솔루션 : (전자결재 / 조직도 / 근태관리(휴가) / 공간예약 / 방문자관리(주차) / e-Learning 시스템 / 경비지출관리 / 주차관리 / 자원예약 / 구매시스템 / 자산관리 등)\n\n\n- (선택) 연동해야하는 내부시스템 : (회계시스템, 인사시스템, SAP 등)\n\n\n- 문의내용 : \n\n\n\n\n`,
};

const baseData = {
  name: {
    isRequired: true,
    value: '',
    isError: false,
  },
  phone: {
    isRequired: true,
    value: '',
    isError: false,
  },
  firm: {
    isRequired: true,
    value: '',
    isError: false,
  },
  jobRole: {
    isRequired: true,
    value: '',
    isError: false,
  },
  email: {
    isRequired: true,
    value: '',
    isError: false,
  },
  industry: {
    isRequired: true,
    value: null,
    isError: false,
  },
  kindInquiry: {
    isRequired: true,
    value: '',
    isError: false,
  },
  kindSecondary: {
    isRequired: true,
    value: null,
    isError: false,
  },
  detailData: {
    isRequired: true,
    value: '',
    isError: false,
  },
  addedFile: {
    isRequired: false,
    value: [],
  },
  chkAgree1: {
    isRequired: true,
    value: false,
    isError: false,
  },
  chkAgree2: {
    isRequired: false,
    value: false,
  },
};

// 인풋값
const inputValues = ref(copyObject(baseData));

const checkInputValue = (property) => {
  //blur시 값 없는지 여부 확인해서 빨갛게 바꾸기
  property.isError = !property.value;
};

const valueCheck = (item, valueFn) => {
  item.isError = !valueFn(item.value);
};

const setBusinessTemplate = (key) => {
  inputValues.value.detailData.value = businessTemplateInfo[key] || '';
};

const selectItem = (valueName, value) => {
  //셀렉트 아이템 선택
  inputValues.value[valueName].value = value;
  checkInputValue(inputValues.value[valueName]);
};

const selectTypeItem = (valueName, value) => {
  //셀렉트 아이템 선택
  if (valueName === 'kindInquiry') {
    inputValues.value.kindSecondary.value = null;
  }
  inputValues.value[valueName].value = value;
  checkInputValue(inputValues.value[valueName]);

  setBusinessTemplate(value.key);
};

const isFormFilled = computed(() => {
  //양식채워졌는지 여부 확인값
  return Object.keys(inputValues.value).some((itemKey) => {
    const formItem = inputValues.value[itemKey];
    return formItem.isRequired && (!formItem.value || formItem.isError);
  });
});

const setFileList = (files) => {
  inputValues.value.addedFile.value = files;
};

// 초기화 기능
const initData = () => {
  inputValues.value = copyObject(baseData);
};

const submitInquiry = async () => {
  // 파일 업로드 테스트 할 것
  const formData = new FormData();
  const {
    name,
    phone,
    firm,
    jobRole,
    industry,
    email,
    kindInquiry,
    kindSecondary,
    detailData,
    addedFile,
    chkAgree1,
    chkAgree2,
  } = inputValues.value;
  const jsonData = {
    inquiryType: kindInquiry.value.key,
    privacyInfoName: name.value,
    privacyInfoCompany: firm.value,
    privacyInfoIndustry: industry.value.name,
    privacyInfoContact: phone.value,
    privacyInfoPosition: jobRole.value,
    privacyInfoEmail: email.value,
    inquiryCategory: kindSecondary.value.key,
    inquiryContent: detailData.value,
    acceptPrivacyPolicy: chkAgree1.value,
    acceptMarketingPolicy: chkAgree2.value,
  };

  // // jsonData 값 세팅
  formData.append(
    'inquiryRequest',
    new Blob([JSON.stringify(jsonData)], { type: 'application/json' }),
  );
  // fileData 값 세팅
  addedFile.value.forEach((file) => {
    formData.append('multipartFileList', file);
  });

  useApi(
    'createInquiry',
    () => {
      //조건검색시
      return createInquiry(formData).then((res) => {
        if (res) {
          alert('문의하기 완료\n빠른 시일 내에 답변 드리겠습니다.');
          inquiryConfirmToast(res.acceptMarketingPolicy, res.acceptDate);
          initData();
          consultLayer.toggleConsultLayer('close');
        }
      });
    },
    {},
  );
};

// 클라이언트 사이드에서 처리해도 무방해 보임
// 같은 api를 호출하는 형태, api 인터페이스를 바꾸면 1번의 호출/내부 필터링으로 사용할 수 있음
const { data: categoryData } = await useAsyncData('category', async () => {
  const [inquiry, industry, business, etc] = await Promise.all([
    fetchCategory('CATE000094'),
    fetchCategory('CATE000098'),
    fetchCategory('CATE000020'),
    fetchCategory('CATE000097'),
  ]);

  return { inquiry, industry, business, etc };
});

const madeOptList = (list, exeption) => {
  //리스트중 비즈니스 리스트에서 워크솔루션 항목 비노출 처리 요청으로 exeption 추가 - 현재 텍스트 비교로 처리되어있으나 be작업가능시 노출여부 플래그 추가해서 내려주면 해당하는 값으로 비노출 처리 진행되면 좋을듯함.
  const exeptedList = list.filter((ele) => ele.categoryName !== exeption);

  return exeptedList.map(({ categoryName, categoryCode }) => ({
    name: categoryName,
    key: categoryCode,
  }));
};

const madeSelectedData = (optList = [], selectedData = {}, comparisonKey = 'id') => {
  return optList.map((opt) => ({
    ...opt,
    isSelected: selectedData && opt[comparisonKey] === selectedData[comparisonKey],
  }));
};

const industryOpt = computed(() => {
  return madeSelectedData(
    madeOptList(categoryData.value.industry),
    inputValues.value.industry.value,
    'key',
  );
});

const inquiryOpt = computed(() => {
  return madeSelectedData(
    madeOptList(categoryData.value.inquiry),
    inputValues.value.kindInquiry.value,
    'key',
  );
});

const businessOpt = computed(() => {
  return categoryData.value.business.map((item) => {
    return {
      name: item.categoryName,
      key: item.categoryCode,
      serviceCategory: madeSelectedData(
        madeOptList(item.serviceCategory, '워크솔루션'),
        inputValues.value.kindSecondary.value,
        'key',
      ),
    };
  });
});
const etcOpt = computed(() => {
  return categoryData.value.etc.map((item) => {
    return {
      name: item.categoryName,
      key: item.categoryCode,
      serviceCategory: madeSelectedData(
        madeOptList(item.serviceCategory),
        inputValues.value.kindSecondary.value,
        'key',
      ),
    };
  });
});

const SecondarySelectData = computed(() => {
  const business = {
    label: '비즈니스',
    placeholder: '비즈니스유형',
    data: businessOpt.value,
  };
  const etc = {
    label: '세부유형',
    placeholder: '세부유형',
    data: etcOpt.value,
  };
  return inputValues.value.kindInquiry.value.key === 'CATE000097' ? etc : business;
});

const route = useRoute();
onMounted(async () => {
  //카테고리 선택 파라이터가 있다면 실행
  if (route.query.cate && route.query.secondary) {
    //cate파라미터값에따른 선택 값 찾기
    const selectedCate = inquiryOpt.value.find((ele) => ele.key === route.query.cate);
    selectTypeItem('kindInquiry', selectedCate);
    //객체내부 배열들 한개의 리스트로 만들기
    let ListSecondaryData = [];
    SecondarySelectData.value.data.forEach((ele) => {
      ListSecondaryData = [...ListSecondaryData, ...ele.serviceCategory];
    });
    //secondary파라미터값에따른 선택 값 찾기
    const selectedSecondary = ListSecondaryData.find((item) => item.key === route.query.secondary);
    selectTypeItem('kindSecondary', selectedSecondary);
  }
});
</script>
<style lang="scss" scoped>
// 스크롤바 커스터마이징
.body_layer::-webkit-scrollbar {
  width: 4px;
}
.body_layer::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}
.body_layer::-webkit-scrollbar-track {
  background: #fff;
}

.wrap_layer {
  display: flex;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
}

.layer_inquiry {
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  max-width: 1120px;
  width: 58%;
  max-height: 80%;
  background-color: #fff;
  padding: 40px;
  border-radius: 4px;
}
.head_layer {
  padding-bottom: 12px;
  h2 {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.5px;
  }
}
.body_layer {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 48px);
}
.area_form {
  border-top: 1px solid #222;
  & + .area_form {
    margin-top: 40px;
  }
}
.item_title {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: 74px;
  padding: 12px 40px;
  border-bottom: 1px solid #e1e1e1;
  em {
    width: 200px;
    color: #4c4c4c;
  }
  em,
  span {
    font-size: 16px;
  }
}
.item_form {
  box-sizing: border-box;
  min-height: 74px;
  padding: 12px 40px;
  border-bottom: 1px solid #e1e1e1;
}

.area_agree {
  margin-top: 40px;
}
.box_agree {
  padding: 32px;
  border-radius: 10px;
  background-color: #f5f5f5;
  & + .box_agree {
    margin-top: 16px;
  }
  .txt_agree {
    margin-top: 12px;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: #555;
    a {
      text-decoration: underline;
      font-weight: bold;
    }
  }
}
.btn_close {
  position: absolute;
  top: 24px;
  right: 24px;
  @include setSize(24px, 24px);
  @include closeBtnIcon();
}
.wrap_btn {
  display: flex;
  justify-content: center;
  margin: 24px -4px 0;
}
.area_error {
  margin-top: 8px;
  font-size: 13px;
  color: #f8626c;
  span {
    display: block;
    line-height: 1.46;
    margin-top: 4px;
  }
}
@media screen and (max-width: 1023px) {
  .wrap_layer {
    align-items: end;
  }
  .layer_inquiry {
    width: 100%;
    max-width: 100%;
    top: auto;
    bottom: 0;
    left: 0;
    transform: translate(0, 0);
    padding: 0;
    border-radius: 8px 8px 0 0;
  }
  .head_layer {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    padding-bottom: 0;
    padding: 16px 20px;
    h2 {
      font-size: 16px;
      font-weight: 700;
    }
  }
  .body_layer {
    overflow-y: auto;
    height: 100%;
    padding: 20px 20px 104px 20px;
  }
  .area_form {
    padding-bottom: 24px;
    border-bottom: 1px solid #f0f0f0;
    border-top: none;
  }
  .item_title {
    display: block;
    padding: 16px;
    border-bottom: none;
    border-radius: 10px;
    background-color: #f5f5f5;
    em {
      display: block;
      width: 100%;
      margin-bottom: 8px;
      font-weight: 500;
      color: #1a1a1a;
    }
    span {
      font-weight: 400;
      color: #666;
      strong {
        font-weight: 400;
      }
    }
    em,
    span {
      font-size: 14px;
    }
  }
  .item_form {
    min-height: auto;
    margin-top: 24px;
    padding: 0;
    border-bottom: none;
  }
  .area_agree {
    margin-top: 24px;
  }
  .btn_close {
    top: 16px;
    right: 16px;
  }
  .wrap_btn {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
  }
}
</style>
