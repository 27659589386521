import { METHOD, makeApiMethod } from './common';

const BASE_URL = '/api/public';
// 기업 DKT 기업사이트 정책
// 추가 정보 확인되면 정리 필요(추가 에러케이스, 노출 케이스)
const DEAFULT_CODE = 500;
// 추후 메시지 세팅이 필요할 수 있으니, object로 세팅함
const ERROR_CODE = {
  401: {
    viewCode: 404,
  },
  403: {
    viewCode: 404,
  },
  404: {
    viewCode: 404,
  },
  500: {
    viewCode: 500,
  },
};

// auto imported
// eslint-disable-next-line no-undef
const apiPublic = $fetch.create({
  baseURL: BASE_URL,
  headers: {},
  onRequestError() {
    // 기존에는 400 에러 케이스는 없었던걸로 보임
    // auto imported
    // eslint-disable-next-line no-undef
    showError({ statusCode: DEAFULT_CODE });
  },
  onResponseError({ response }) {
    // auto imported
    // eslint-disable-next-line no-undef
    showError({ statusCode: ERROR_CODE[response.status].viewCode || DEAFULT_CODE });
  },
});

export const get = (endPoint, options) =>
  makeApiMethod(apiPublic, METHOD.GET)(endPoint, options).then(
    // res: { success: boolean, data: any, statusCode: number }
    (res) => res.result,
  );

export const post = (endPoint, options) =>
  makeApiMethod(apiPublic, METHOD.POST)(endPoint, options).then((res) => res.result);
