<template>
  <div class="wrap_layer">
    <div class="layer_download">
      <div class="head_layer">
        <h2>{{ downloadOpen.isLink ? downloadOpen.titleName : '회사 / 소개서 다운로드' }}</h2>
      </div>
      <div class="body_layer">
        <div class="inner_body">
          <form class="form_download">
            <div class="area_form">
              <div class="item_title">
                <em>{{ downloadOpen.isLink ? '소개서' : '다운로드 파일' }}</em>
                <span
                  >회사소개서 > <strong>{{ downloadOpen.titleName }}</strong></span
                >
              </div>
              <div class="item_form">
                <InputBasic
                  :id="'name'"
                  v-model="inputValues.name.value"
                  :label="'이름'"
                  :isEssential="true"
                  :maxlength="6"
                  :placeHolder="'이름을 입력해주세요.'"
                  :isError="inputValues.name.isError"
                  @onInputEvent="valueCheck(inputValues.name, checkValidName)"
                />
              </div>
              <div class="item_form">
                <InputBasic
                  :id="'email'"
                  v-model="inputValues.email.value"
                  :label="'이메일'"
                  :isEssential="true"
                  :placeHolder="'이메일을 입력해주세요.'"
                  :isError="inputValues.email.isError"
                  @onInputEvent="valueCheck(inputValues.email, checkValidEmail)"
                />
              </div>
              <div class="item_form">
                <InputBasic
                  :id="'firm'"
                  v-model="inputValues.firm.value"
                  :label="'회사(소속)'"
                  :isEssential="true"
                  :maxlength="20"
                  :placeHolder="'회사(소속)을 입력해주세요.'"
                  :isError="inputValues.firm.isError"
                  @onInputEvent="valueCheck(inputValues.firm, checkValidAboutFirm)"
                />
              </div>
              <div class="item_form">
                <InputBasic
                  :id="'jobRole'"
                  v-model="inputValues.jobRole.value"
                  :label="'직급'"
                  :isEssential="true"
                  :maxlength="20"
                  :placeHolder="'직급을 입력해주세요.'"
                  :isError="inputValues.jobRole.isError"
                  @onInputEvent="valueCheck(inputValues.jobRole, checkValidAboutFirm)"
                />
              </div>
            </div>
          </form>
          <div class="area_error">
            <span v-if="inputValues.name.isError">· 이름이 정확한지 확인해 주세요.</span>
            <span v-if="inputValues.email.isError">· 이메일이 정확한지 확인해 주세요.</span>
            <span v-if="inputValues.firm.isError">· 회사(소속)이 정확한지 확인해 주세요.</span>
            <span v-if="inputValues.jobRole.isError">· 직급이 정확한지 확인해 주세요.</span>
          </div>
          <div class="area_agree">
            <div class="box_agree">
              <CheckboxBasic
                :id="'chkAgree1'"
                v-model="inputValues.chkAgree1.value"
                :title="'개인정보 수집·이용 동의 (필수)'"
              />
              <p class="txt_agree">
                자료 다운로드 이력 관리를 위하여 이름, 이메일, 회사(소속), 직급을 수집합니다.
                <br />수집된 개인정보는 <strong>5년간</strong> 보관후 파기됩니다. 위 동의를 거부할
                권리가 있으며, 동의를 거부하실 경우 자료 다운로드 및 결과 회신이 제한됩니다. 더
                자세한 내용에 대해서는
                <LinkBasic
                  text="개인정보처리방침"
                  link="https://policy.dktechin.com/privacy-policy"
                  :isBlank="true"
                />을 참고하시기 바랍니다.
              </p>
            </div>
            <div class="box_agree">
              <CheckboxBasic
                :id="'chkAgree2'"
                v-model="inputValues.chkAgree2.value"
                :title="'마케팅 활용 및 수신 동의(선택)'"
              />
              <p class="txt_agree">
                회사 및 회사의 서비스와 관련된
                <strong>광고 및 정보 제공(이벤트, 서비스 안내, 생활 정보 등)</strong> 을 위하여
                이름, 연락처, 이메일을 수집합니다. 수집된 개인정보는 <strong>5년간</strong> 보관 후
                파기됩니다. 위 동의를 거부할 권리가 있으며, 동의를 거부하실 경우 광고 및 정보 제공이
                제한됩니다. 더 자세한 내용에 대해서는
                <LinkBasic
                  text="개인정보처리방침"
                  link="https://policy.dktechin.com/privacy-policy"
                  :isBlank="true"
                />을 참고하시기 바랍니다.
              </p>
            </div>
          </div>
          <div class="wrap_btn">
            <ButtonPopup
              :nameBtn="'다운로드'"
              :style="'type_black'"
              :isDisabled="!isFormFilled"
              @click="submitHandler()"
            />
          </div>
        </div>
      </div>
      <button
        type="button"
        class="btn_close"
        @click="downloadOpen.closeDownloadLayer"
      >
        <span class="screen_out">팝업 닫기</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { createDownloadInquiry } from '/api/inquiry';
import { useDimmedOpen, useDownloadOpen } from '/stores/store';
const dimmedOpen = useDimmedOpen();
const downloadOpen = useDownloadOpen();

// 인풋값
const baseData = {
  name: {
    isRequired: true,
    value: '',
    isError: false,
  },
  email: {
    isRequired: true,
    value: '',
    isError: false,
  },
  firm: {
    isRequired: true,
    value: '',
    isError: false,
  },
  jobRole: {
    isRequired: true,
    value: '',
    isError: false,
  },
  chkAgree1: {
    isRequired: true,
    value: '',
    isError: false,
  },
  chkAgree2: {
    isRequired: false,
    value: '',
  },
};

// 인풋값
const inputValues = ref(copyObject(baseData));

const valueCheck = (item, valueFn) => {
  item.isError = !valueFn(item.value);
};

const isFormFilled = computed(() => {
  //양식채워졌는지 여부 확인값
  for (const key in inputValues.value) {
    if (Object.keys(inputValues.value[key]).includes('isError')) {
      //isError값이 있는 애들만 체크
      if (!inputValues.value[key].value || inputValues.value[key].isError) {
        return false;
      }
    }
  }
  return true;
});

// 초기화 기능
const initData = () => {
  inputValues.value = copyObject(baseData);
};

const submitHandler = async () => {
  // const formData = new FormData();
  const { name, firm, jobRole, email, chkAgree1, chkAgree2 } = inputValues.value;
  const jsonData = {
    inquiryType: 'CATE000007',
    privacyInfoName: name.value,
    privacyInfoEmail: email.value,
    privacyInfoCompany: firm.value,
    privacyInfoPosition: jobRole.value,
    acceptPrivacyPolicy: chkAgree1.value,
    acceptMarketingPolicy: chkAgree2.value,
  };

  jsonData.fileName = downloadOpen.titleName;
  jsonData.fileUrl = downloadOpen.fileLink;
  jsonData.serviceName = downloadOpen.serviceName;

  await createDownloadInquiry(jsonData).then((res) => {
    if (res) {
      inquiryConfirmToast(res.acceptMarketingPolicy, res.acceptDate);
      downloadOpen.isLink
        ? window.open(`${downloadOpen.fileLink}`)
        : window.open(`${downloadOpen.fileLink}?download`);
      initData();
      downloadOpen.closeDownloadLayer();
    }
  });
};

onMounted(() => {
  dimmedOpen.toggleDimmed('open');
});
onUnmounted(() => {
  dimmedOpen.toggleDimmed('close');
});
</script>
<style lang="scss" scoped>
// 스크롤바 커스터마이징
.body_layer::-webkit-scrollbar {
  width: 4px;
}
.body_layer::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}
.body_layer::-webkit-scrollbar-track {
  background: #fff;
}

.wrap_layer {
  display: flex;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
}

.layer_download {
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  max-width: 1120px;
  width: 58%;
  max-height: 80%;
  background-color: #fff;
  padding: 40px;
  border-radius: 4px;
}
.head_layer {
  padding-bottom: 12px;

  h2 {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.5px;
  }
}
.body_layer {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 48px);
}
.area_form {
  border-top: 1px solid #222;
  & + .area_form {
    margin-top: 40px;
  }
}
.item_title {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: 74px;
  padding: 12px 40px;
  border-bottom: 1px solid #e1e1e1;
  word-break: keep-all;
  word-break: break-all;
  em {
    width: 200px;
    color: #4c4c4c;
  }
  em,
  span {
    font-size: 16px;
  }
}
.item_form {
  box-sizing: border-box;
  min-height: 74px;
  padding: 12px 40px;
  border-bottom: 1px solid #e1e1e1;
}

.area_agree {
  margin-top: 40px;
}
.box_agree {
  padding: 32px;
  border-radius: 10px;
  background-color: #f5f5f5;
  & + .box_agree {
    margin-top: 16px;
  }
  .txt_agree {
    margin-top: 12px;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: #555;
    a {
      text-decoration: underline;
      font-weight: bold;
    }
  }
}
.btn_close {
  position: absolute;
  top: 24px;
  right: 24px;
  @include setSize(24px, 24px);
  @include closeBtnIcon();
}
.wrap_btn {
  display: flex;
  justify-content: center;
  margin: 24px -4px 0;
}
.area_error {
  margin-top: 8px;
  font-size: 13px;
  color: #f8626c;
  span {
    display: block;
    line-height: 1.46;
    margin-top: 4px;
  }
}
@media screen and (max-width: 1023px) {
  .wrap_layer {
    align-items: end;
  }
  .layer_download {
    width: 100%;
    max-width: 100%;
    top: auto;
    bottom: 0;
    left: 0;
    transform: translate(0, 0);
    padding: 0;
    border-radius: 8px 8px 0 0;
  }
  .head_layer {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    padding-bottom: 0;
    padding: 16px 20px;
    h2 {
      font-size: 16px;
      font-weight: 700;
    }
  }
  .body_layer {
    overflow-y: auto;
    height: 100%;
    padding: 20px 20px 104px 20px;
  }
  .area_form {
    padding-bottom: 24px;
    border-bottom: 1px solid #f0f0f0;
    border-top: none;
  }
  .item_title {
    display: block;
    padding: 16px;
    border-bottom: none;
    border-radius: 10px;
    background-color: #f5f5f5;
    em {
      display: block;
      width: 100%;
      margin-bottom: 8px;
      font-weight: 500;
      color: #1a1a1a;
    }
    span {
      font-weight: 400;
      color: #666;
      strong {
        font-weight: 400;
      }
    }
    em,
    span {
      font-size: 14px;
    }
  }
  .item_form {
    min-height: auto;
    margin-top: 24px;
    padding: 0;
    border-bottom: none;
  }
  .area_agree {
    margin-top: 24px;
  }
  .btn_close {
    top: 16px;
    right: 16px;
  }
  .wrap_btn {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
  }
}
</style>
