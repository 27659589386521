<template>
  <div class="layer_share">
    <div class="head_layer">
      <h2>공유하기</h2>
    </div>
    <div class="body_layer">
      <div class="area_links">
        <ul>
          <li
            v-for="(item, index) in shareList"
            :key="`${index}_share`"
          >
            <button
              type="button"
              class="link_share"
              :aria-label="item.title"
              @click="item.fn"
            >
              <div class="wrap_thumb">
                <img
                  :src="generateWebpImage(item.icon, 'C144x144')"
                  alt=""
                />
              </div>
              <span class="txt_share">{{ item.name }}</span>
            </button>
          </li>
        </ul>
      </div>
      <div class="bottom_layer">
        <div class="wrap_input">
          <input
            ref="urlInput"
            type="text"
            readonly="readonly"
            :value="shareData.shareUrl"
          />
        </div>
        <button
          ref="btnCopy"
          type="button"
          class="btn_copy"
          aria-label="링크 복사하기"
          @click="shareCopy()"
        >
          URL 복사
        </button>
      </div>
    </div>
    <button
      type="button"
      class="btn_close"
      @click="shareLayer.closeShareLayer"
    >
      <span class="screen_out">팝업 닫기</span>
    </button>
  </div>
</template>
<script setup>
import { useDimmedOpen, useShareOpen } from '/stores/store';
const dimmedOpen = useDimmedOpen();
const shareLayer = useShareOpen();

const shareData = computed(() => {
  return shareLayer.shareData;
});
const urlInput = ref('');
const btnCopy = ref('');
const shareKakao = () => {
  //카카오톡 공유하기
  Kakao.Share.sendDefault({
    objectType: 'feed',
    content: {
      title: shareData.value.shareTitle,
      imageUrl: shareData.value.shareImage,
      link: {
        mobileWebUrl: shareData.value.shareUrl,
        webUrl: shareData.value.shareUrl,
      },
    },
    installTalk: true,
  });
};

const shareFacebook = () => {
  const encodedShareUrl = `https://facebook.com/sharer.php?u=${encodeURIComponent(shareData.value.shareUrl)}`;
  //페이스북 공유하기
  window.open(encodedShareUrl, '_blank', 'noopener,noreferrer');
};

const shareLinkedIn = () => {
  //링크드인 공유하기
  window.open(
    `https://linkedin.com/shareArticle?url=${encodeURIComponent(shareData.value.shareUrl)}`,
    '_blank',
  );
};

const shareCopy = () => {
  //링크복사하기
  urlInput.value.select();
  document.execCommand('copy');
  window.alert('URL이 클립보드에 복사되었습니다.');

  setTimeout(() => {
    btnCopy.value.focus(); //접근성 얼럿 확인후 포커스 기존위치로 이동
  }, 1);
};
const shareList = [
  {
    type: 'kakao',
    icon: 'https://t1.kakaocdn.net/dkt_corp/service/ico_kakaotalk.png',
    name: '카카오톡',
    title: '카카오톡으로 공유하기, 새창열림',
    fn: shareKakao,
  },
  {
    type: 'facebook',
    icon: 'https://t1.kakaocdn.net/dkt_corp/service/ico_facebook.png',
    name: '페이스북',
    title: '페이스북으로 공유하기, 새창열림',
    fn: shareFacebook,
  },
  {
    type: 'linked',
    icon: 'https://t1.kakaocdn.net/dkt_corp/service/ico_linkedin.png',
    name: '링크드인',
    title: '링크드인으로 공유하기, 새창열림',
    fn: shareLinkedIn,
  },
];

onMounted(() => {
  dimmedOpen.toggleDimmed('open');
});
onUnmounted(() => {
  dimmedOpen.toggleDimmed('close');
});
</script>
<style lang="scss" scoped>
.layer_share {
  box-sizing: border-box;
  position: fixed;
  z-index: 100000;
  left: 50%;
  top: 50%;
  margin-left: -160px;
  transform: translateY(-50%);
  background-color: #fff;
  border-radius: 8px;
  width: 320px;
}
.head_layer {
  padding: 16px 0;
  text-align: center;
  h2 {
    font-size: 16px;
    letter-spacing: -0.5px;
  }
}
.body_layer {
  padding: 24px 20px;
}
.area_links {
  padding: 0 16px;
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 24px;
    column-gap: 16px;
  }
  .link_share {
    font-size: 0;
    img {
      width: 100%;
    }
    .txt_share {
      display: block;
      margin-top: 8px;
      text-align: center;
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: -0.3px;
      color: #4c4c4c;
    }
  }
}
.bottom_layer {
  display: flex;

  margin-top: 24px;
  .wrap_input {
    input {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 13px 16px;
      line-height: 20px;
      font-size: 15px;
      letter-spacing: -0.5px;
      border-radius: 4px;
      border: 1px solid #d7d7d7;
    }
  }
  .btn_copy {
    box-sizing: border-box;
    flex-shrink: 0;
    height: 48px;
    line-height: 48px;
    margin-left: 8px;
    padding: 0 9px 0 27px;
    font-size: 15px;
    color: #4c4c4c;
    background-color: #f6f6f6;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    @include backgroundImage(
      "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m17.128 11.343-5.544 5.544c-1.065 1.066-2.858 1-4.005-.148-1.148-1.147-1.214-2.94-.149-4.005l6.027-6.027c.665-.665 1.787-.624 2.503.093.717.716.758 1.838.093 2.503l-5.062 5.063c-.265.265-.716.248-1.002-.037-.285-.286-.302-.737-.037-1.002l4.58-4.58-.778-.779-4.58 4.58c-.666.666-.624 1.787.092 2.504.717.716 1.838.758 2.504.092l5.062-5.062c1.065-1.065.999-2.858-.149-4.005-1.147-1.148-2.94-1.214-4.005-.149l-6.026 6.027c-1.466 1.466-1.375 3.929.204 5.507 1.578 1.579 4.041 1.67 5.507.204l5.544-5.544-.779-.779z' fill='%234C4C4C'/%3E%3C/svg%3E",
      24px 24px,
      top 13px left 3px
    );
  }
}
.btn_close {
  position: absolute;
  top: 16px;
  right: 16px;
  @include setSize(24px, 24px);
  @include closeBtnIcon();
}
@media screen and (max-width: 360px) {
  .layer_share {
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    margin-left: 0;
    width: calc(100% - 40px);
  }
}
</style>
