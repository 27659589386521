<template>
  <footer class="doc-footer">
    <div class="wrap_footer">
      <div class="top_footer">
        <div class="inner_footer type_sns">
          <NuxtLink
            to="/"
            class="link_logo"
          >
            <img
              src="https://t1.kakaocdn.net/dkt_corp/service/logo_header.svg"
              alt="디케이테크인"
            />
            <span class="screen_out">홈으로 바로가기</span>
          </NuxtLink>
          <div class="wrap_sns">
            <LinkBasic
              text=""
              link="https://dktechin.tistory.com/"
              :isBlank="true"
              irText="티스토리 새창열림"
              class="type_tstory"
              data-tiara-action-name="푸터_티스토리_클릭"
              data-tiara-action-kind="ClickContent"
              data-tiara-layer="footer footer_tstory_link"
            />
            <LinkBasic
              text=""
              link="https://www.facebook.com/dktechin"
              :isBlank="true"
              irText="페이스북 새창열림"
              class="type_facebook"
              data-tiara-action-name="푸터_페이스북_클릭"
              data-tiara-action-kind="ClickContent"
              data-tiara-layer="footer footer_facebook_link"
            />
            <LinkBasic
              text=""
              link="https://www.instagram.com/dktechin_dkt"
              :isBlank="true"
              irText="인스타그램 새창열림"
              class="type_insta"
              data-tiara-action-name="푸터_인스타그램_클릭"
              data-tiara-action-kind="ClickContent"
              data-tiara-layer="footer footer_insta_link"
            />
            <button
              type="button"
              class="type_up"
              @click="scrollTop"
            >
              <span class="screen_out">맨위로 이동하기</span>
            </button>
          </div>
        </div>
        <address class="inner_footer type_info">
          <span>(주)디케이테크인</span>
          <span>대표이사 : 이원주</span>
          <span>경기도 성남시 분당구 판교역로 235, 에이치스퀘어 N동 3층</span>
          <br />
          <span>고객센터 : 1555-0055</span><br />
          <small>© dk techin Corp. All rights reserved.</small>
        </address>
      </div>
      <div class="bottom_footer">
        <div class="inner_footer type_links">
          <div class="left_links">
            <LinkBasic
              text="개인정보처리방침"
              link="https://policy.dktechin.com/privacy-policy"
              :isBlank="true"
              class="txt_emph"
              data-tiara-action-name="푸터_개인정보처리방침_클릭"
              data-tiara-action-kind="ClickContent"
              data-tiara-layer="footer footer_privacyPolicy_link"
            />
            <LinkBasic
              text="위치정보사업 이용약관"
              link="https://policy.dktechin.com/location/dktechin"
              :isBlank="true"
              class="txt_emph"
              data-tiara-action-name="푸터_위치정보사업_이용약관_클릭"
              data-tiara-action-kind="ClickContent"
              data-tiara-layer="footer footer_localPrivacy_link"
            />
            <LinkBasic
              text="공지사항"
              link="https://policy.dktechin.com/notice"
              :isBlank="true"
              data-tiara-action-name="푸터_공지사항_클릭"
              data-tiara-action-kind="ClickContent"
              data-tiara-layer="footer footer_notice_link"
            />
            <LinkBasic
              text="카카오 i 기술문서"
              link="https://docs.kakaoi.ai/"
              :isBlank="true"
              data-tiara-action-name="푸터_카카오_i_기술문서_클릭"
              data-tiara-action-kind="ClickContent"
              data-tiara-layer="footer footer_kakaiotech_link"
            />
            <LinkBasic
              text="오시는 길"
              link="/waytocome"
              :isBlank="false"
              data-tiara-action-name="푸터_오시는길_클릭"
              data-tiara-action-kind="ClickContent"
              data-tiara-layer="footer footer_waytocome_link"
            />
            <LinkBasic
              text="고객센터"
              link="/inquiry"
              :isBlank="false"
              data-tiara-action-name="푸터_고객센터_클릭"
              data-tiara-action-kind="ClickContent"
              data-tiara-layer="footer footer_inquiry_link"
            />
          </div>
          <div :class="['related_links', { on: isRelatedOpen }]">
            <strong>
              <button
                type="button"
                class="btn_related"
                aria-expanded="false"
                @click="toggleRelated"
              >
                관련사이트
              </button>
            </strong>
            <ul class="list_subinfo">
              <li>
                <LinkBasic
                  text="카카오워크"
                  link="https://www.kakaowork.com/"
                  :isBlank="true"
                  data-tiara-action-name="푸터_관련사이트_카카오워크_클릭"
                  data-tiara-action-kind="ClickContent"
                  data-tiara-layer="footer_related footer_related_kakaowork_link"
                />
              </li>
              <li>
                <LinkBasic
                  text="카카오 i"
                  link="https://kakao.ai/"
                  :isBlank="true"
                  data-tiara-action-name="푸터_관련사이트_카카오i_클릭"
                  data-tiara-action-kind="ClickContent"
                  data-tiara-layer="footer_related footer_related_kakaoi_link"
                />
              </li>
              <li>
                <LinkBasic
                  text="카카오"
                  link="https://www.kakaocorp.com/page/"
                  :isBlank="true"
                  data-tiara-action-name="푸터_관련사이트_카카오_클릭"
                  data-tiara-action-kind="ClickContent"
                  data-tiara-layer="footer_related footer_related_kakao_link"
                />
              </li>
            </ul>
          </div>
        </div>
        <div class="logo_certify">
          <LinkBasic
            text=""
            link="https://dktechin.tistory.com/82"
            irText="2024 대한민국 일자리 으뜸기업 인증 정보 새창열림"
            :isBlank="true"
            class="link_certify type_1"
          />
          <LinkBasic
            text=""
            link="https://dktechin.com/news/9"
            :irText="'가족친화 우수기업 정보 새창열림'"
            :isBlank="true"
            class="link_certify type_2"
          />
          <LinkBasic
            text=""
            link="https://dktechin.com/news/36"
            irText="bsi iso/iec 27701 privacy information management certified 새창열림"
            :isBlank="true"
            class="link_certify type_3"
          />
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup>
const isRelatedOpen = ref(false);

const toggleRelated = () => {
  isRelatedOpen.value = !isRelatedOpen.value;
};
</script>
<style lang="scss" scoped>
.doc-footer {
  position: relative;
  z-index: 1;
  background-color: #fff;
  border-top: 1px solid #e5e5e5;
}
.wrap_footer {
  box-sizing: border-box;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 64px 128px;
}
.top_footer {
  .inner_footer {
    &.type_sns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 28px;
    }
    &.type_info {
      margin-top: 24px;
    }
  }
}
.wrap_sns {
  font-size: 0;
  a,
  button {
    display: inline-block;
    vertical-align: top;
    @include setSize(28px, 28px);
    & + a,
    & + button {
      margin-left: 24px;
    }
    &.type_tstory {
      @include tstoryIcon();
    }
    &.type_facebook {
      @include facebookIcon();
    }
    &.type_insta {
      @include instaIcon();
    }
    &.type_up {
      @include upIcon();
    }
  }
}
.inner_footer.type_info {
  span,
  small {
    font-size: 15px;
    line-height: 1.8;
    color: #999;
    & + span {
      margin-left: 16px;
      &:before {
        content: '';
        display: inline-block;
        width: 1px;
        height: 16px;
        background-color: #f0f0f0;
        margin-right: 16px;
      }
    }
  }
}
.bottom_footer {
  margin: 24px 0 24px;
  padding-top: 24px;
  border-top: 1px solid #f0f0f0;
}
.inner_footer.type_links {
  display: flex;
  justify-content: space-between;
  .left_links {
    margin: -8px 0 0 -48px;
    a {
      display: inline-block;
      vertical-align: top;
      margin: 8px 0 0 48px;
      font-size: 16px;
      color: #999;
    }
  }
}
.related_links {
  position: relative;
  width: 220px;
  margin-left: 24px;
  flex-shrink: 0;
  &.on {
    .list_subinfo {
      display: block;
    }
  }
  .btn_related {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 33px;
    padding: 0 12px 0 16px;
    background-color: #eee;
    border-radius: 4px;
    color: #666;
    font-size: 14px;
    font-weight: 500;
    &:after {
      content: '';
      display: inline-block;
      @include setSize(16px, 16px);
      @include relatedPlusIcon();
    }
  }
  .list_subinfo {
    display: none;
    box-sizing: border-box;
    position: absolute;
    bottom: 37px;
    padding: 20px;
    width: 100%;
    border-radius: 4px;
    background-color: #eee;
    li {
      & + li {
        margin-top: 13px;
      }
      a {
        display: block;
        font-size: 15px;
        letter-spacing: -0.5px;
        color: #666;
        &:after {
          content: '';
          display: inline-block;
          vertical-align: top;
          @include setSize(20px, 20px);
          @include outLinkIconGrey();
        }
        &:hover {
          color: #1a1a1a;
          &:after {
            @include outLinkIconBlack();
          }
        }
      }
    }
  }
}
.logo_certify {
  margin-top: 24px;
  font-size: 0;
  .link_certify {
    display: inline-block;
    vertical-align: top;
    & + .link_certify {
      margin-left: 24px;
    }
    &.type_1 {
      @include setSize(124px, 42px);
      @include backgroundImage(
        'https://t1.kakaocdn.net/dkt_corp/service/certify_img1.png',
        cover,
        center
      );
    }
    &.type_2 {
      @include setSize(152px, 42px);
      @include backgroundImage(
        'https://t1.kakaocdn.net/dkt_corp/service/certify_img2.png',
        cover,
        center
      );
    }
    &.type_3 {
      @include setSize(138px, 42px);
      @include backgroundImage(
        'https://t1.kakaocdn.net/dkt_corp/service/certify_img3_250307.png',
        cover,
        center
      );
    }
  }
}
@media screen and (max-width: 1439px) {
  .wrap_footer {
    padding: 64px 80px;
  }
}
</style>
