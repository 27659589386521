<template>
  <div :class="['wrap_input', { type_error: props.isError }]">
    <span class="label_opt"
      >{{ props.label
      }}<span
        v-if="props.isEssential"
        class="mark_essential"
      >
        *<span class="screen_out">필수입력값</span></span
      ></span
    >
    <div class="inner_input">
      <div
        ref="optBasic"
        class="opt_basic"
        tabindex="-1"
        @focusout="outsideClickEvent"
      >
        <div :class="['selected_item', { type_selected: selectedData }]">
          <button
            type="button"
            :aria-expanded="`${isOpen}`"
            aria-haspopup="listbox"
            class="btn_opt"
            @click="toggleOpt"
          >
            <span>{{ selectedData ? selectedData.name : placeHolder }}</span>
          </button>
        </div>
        <strong class="screen_out">선택 항목 목록</strong>
        <ul
          v-if="props.hasCate"
          role="listbox"
          :class="['list_opt', { active: isOpen }]"
        >
          <li
            v-for="(item, idx) in optList"
            :key="`industry_${idx}`"
          >
            <em class="tit_cate">{{ item.name }}</em>
            <ul class="list_cate">
              <li
                v-for="serviceItem in item.serviceCategory"
                :key="serviceItem.key"
                role="option"
                :aria-selected="`${serviceItem.isSelected}`"
              >
                <button
                  type="button"
                  class="btn_select"
                  @click="selectItem(serviceItem)"
                >
                  {{ serviceItem.name }}
                </button>
              </li>
            </ul>
          </li>
        </ul>
        <ul
          v-else
          ref="listOpt"
          role="listbox"
          :class="['list_opt', { active: isOpen }]"
        >
          <li
            v-for="(item, idx) in optList"
            :key="`industry_${idx}`"
            role="option"
            :aria-selected="`${item.isSelected}`"
          >
            <button
              type="button"
              class="btn_select"
              @click="selectItem(item)"
            >
              {{ item.name }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
const emit = defineEmits(['selectItem', 'onCloseEvent']);
const listOpt = ref();
const isOpen = ref(false);
const optBasic = ref('');
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  isEssential: {
    type: Boolean,
    default: false,
  },
  placeHolder: {
    type: String,
    default: '',
  },
  optList: {
    type: Object,
    default() {
      return {};
    },
  },
  optType: {
    type: String,
    default: '',
  },
  isError: {
    type: Boolean,
    default: false,
  },
  hasCate: {
    type: Boolean,
    default: false,
  },
});

const outsideClickEvent = (e) => {
  if (!optBasic.value.contains(e.relatedTarget)) {
    isOpen.value = false;
  }
};

const selectedData = computed(() => {
  if (props.hasCate) {
    const serviceOptList = props.optList.reduce((acc, cur) => {
      return acc.concat(cur.serviceCategory);
    }, []);
    return serviceOptList.find((opt) => opt.isSelected);
  }
  return props.optList.find((opt) => opt.isSelected);
});

const toggleOpt = () => {
  if (isOpen.value) {
    emit('onCloseEvent');
  }
  isOpen.value = !isOpen.value;
};

const selectItem = (data) => {
  emit('selectItem', props.optType, data);
  toggleOpt();
};
</script>
<style lang="scss" scoped>
// 스크롤바 커스터마이징
.list_opt::-webkit-scrollbar {
  width: 4px;
}
.list_opt::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}
.list_opt::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 8px;
}

.wrap_input {
  display: flex;
  align-items: center;
}
.label_opt {
  width: 184px;
  font-size: 16px;
  color: #4c4c4c;
}
.mark_essential {
  color: #f8626c;
}
.inner_input {
  box-sizing: border-box;
  width: 360px;
  margin-left: 16px;
}
.opt_basic {
  position: relative;
}
.selected_item {
  .btn_opt {
    color: #bbb;
    width: 100%;
    height: 100%;
    padding: 14px 16px;
    line-height: 20px;
    font-size: 15px;
    letter-spacing: -0.5px;
    text-align: left;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    &[aria-expanded='true'] {
      border-color: #222;
    }
    &:after {
      content: '';
      position: absolute;
      top: 16px;
      right: 16px;
      @include setSize(16px, 16px);
      @include backgroundImage(
        "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m8.504 10 3.504-4H5l3.504 4z' fill='%23222'/%3E%3C/svg%3E",
        cover,
        center
      );
    }
  }

  &.type_selected .btn_opt {
    color: #1a1a1a;
  }
}
.list_opt {
  box-sizing: border-box;
  display: none;
  position: absolute;
  z-index: 5;
  top: 52px;
  width: 100%;
  padding: 8px 0;
  border: 1px solid #000;
  border-radius: 4px;
  background-color: #fff;
  max-height: 284px;
  overflow-y: auto;
  &.active {
    display: block;
  }

  li {
    button {
      width: 100%;
      padding: 0 16px;
      line-height: 42px;
      font-size: 15px;
      text-align: left;
      &:hover {
        background-color: #f8f8f8;
      }
    }
  }

  .tit_cate {
    display: block;
    padding: 0 16px;
    line-height: 42px;
    font-size: 15px;
    font-weight: bold;
  }

  .list_cate li button::before {
    content: '- ';
  }
}
// 에러스타일

.wrap_input.type_error {
  .selected_item {
    .btn_opt {
      border: 1px solid #f8626c;
      &[aria-expanded='true'] {
        border-color: #222;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .wrap_input {
    display: block;
  }
  .label_opt {
    width: 100%;
  }
  .inner_input {
    width: 100%;
    margin-left: 0;
    margin-top: 8px;
  }
}
</style>
