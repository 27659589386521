import { get, post } from './corePublic';

/**
 * 추천검색어 관리 > 추천검색어 조회
 * @returns
 */
export const fetchCategoryCs = () => {
  return get(`/csCategory/list`, {});
};

export const fetchTemplateCs = (categoryId) => {
  return get(`csCategory/form/${categoryId}`, {});
};

export const createInquiryCs = (payload) => {
  return post('/csInquiry/save', {
    // headers: {
    //   Authorization:
    //     'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJiaWxseS53b24iLCJhdXRoIjoiUk9MRV9JTlFVSVJZLFJPTEVfTUFOQUdFUiIsImlhdCI6MTczMzM4NjIyNywiZXhwIjoxNzMzNDcyNjI3fQ.acXUsOe8oVysi3aL1Kd9kJcS33TU1StCXvQsgXJEMXunV0n4_yBEAGimUKtgErfpoa4_wThlS2Al-ltiOL_GzA',
    //   'Content-Type': 'multipart/form-data',
    // },
    body: payload,
  });
};
