<template>
  <div class="wrap_toast">
    <h2 class="title_confirm screen_out">수신동의 완료</h2>
    <div class="wrap_text">
      {{ toast.toastText }}
    </div>
  </div>
</template>

<script setup>
import { useToast } from '/stores/store';
const toast = useToast();
</script>
<style lang="scss" scoped>
//토스트 애니메이션
@keyframes toast {
  0% {
    bottom: 0%;
    opacity: 0;
  }
  10% {
    bottom: 48px;
    opacity: 1;
  }
  90% {
    bottom: 48px;
    opacity: 1;
  }
  100% {
    bottom: 0%;
    opacity: 0;
  }
}
.wrap_toast {
  box-sizing: border-box;
  position: fixed;
  bottom: 48px;
  left: 50%;
  transform: translateX(-50%);
  animation-name: toast;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 480px;
  max-width: 900px;
  background-color: #20262d;
  padding: 16px 24px;
  border-radius: 8px;
  color: #fff;
  z-index: 100000;
}
.wrap_text {
  font-size: 16px;
  letter-spacing: -0.5px;
  white-space: pre-wrap;
  text-align: center;
}
@media screen and (max-width: 811px) {
  .wrap_toast {
    width: calc(100% - 40px);
    min-width: 120px;
    min-height: 64px;
    padding: 8px 24px;
  }
  .wrap_text {
    white-space: unset;
  }
}
</style>
