<template>
  <div class="wrap_input">
    <span class="name_input"
      >{{ props.label
      }}<span
        v-if="props.isEssential"
        class="mark_essential"
      >
        *<span class="screen_out">필수입력값</span></span
      ></span
    >
    <div class="inner_input">
      <div class="top_input">
        <input
          :id="props.id"
          ref="fileInput"
          type="file"
          :accept="`${acceptExtensionInfo}`"
          multiple
          @change="writeFileList"
        />
        <label
          :for="props.id"
          class="btn_file"
          role="button"
        >
          첨부파일</label
        >
        <span class="info_input"
          >파일별로 {{ maxFileSize }}MB를 초과할 수 없으며, {{ acceptExtensionTextInfo }}
          파일만 첨부 가능합니다.
        </span>
      </div>
      <ul class="filebox">
        <li
          v-for="(item, idx) in fileList"
          :key="`file_${idx}`"
        >
          {{ item.name }}
          <button
            type="button"
            class="btn_delete"
            @click="deleteFile(idx)"
          ></button>
        </li>
      </ul>
    </div>
  </div>
  <!-- 사용시
   <InputFile
      :id="'addedFile'"
      :label="'첨부파일'"
      :isEssential="false"
      @addFileList="addFileList"
    />
  -->
</template>
<script setup>
const emit = defineEmits(['addFileList']);
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  isEssential: {
    type: Boolean,
    default: false,
  },
  limit: {
    type: Number,
    default: 3,
  },
  maxFileSize: {
    type: Number,
    default: 10,
  },
  acceptInfo: {
    type: Array,
    default() {
      return [];
    },
  },
});

const fileInput = ref();
const fileList = ref([]);

const acceptExtensionInfo = computed(() => {
  return props.acceptInfo.map((extension) => `.${extension}`).join(', ');
});

const acceptExtensionTextInfo = computed(() => {
  return props.acceptInfo.join(', ');
});

const maxByteSize = computed(() => {
  return props.maxFileSize * 1024 * 1024;
});

const writeFileList = (event) => {
  const { files } = event.target;
  // files가 있을 때 동작
  const fileType = event.target.value.split('.').pop();
  if (!props.acceptInfo.includes(fileType)) {
    alert(`등록 가능한 파일 형식이 아닙니다`);
    return;
  }
  for (let i = 0, filesLen = files.length; i < filesLen; i++) {
    const targetFile = files[i];
    if (maxByteSize.value < targetFile.size) {
      alert(`최대 ${props.maxFileSize}MB를 초과할 수 없습니다.`);
      event.target.value = '';
    } else {
      if (fileList.value.length >= props.limit) {
        alert(`최대 ${props.limit}개의 파일을 업로드할 수 있습니다.`);
        event.target.value = '';
        return;
      }
      fileList.value.push(targetFile);
      emit('addFileList', fileList.value);
    }
  }
};
const deleteFile = (idx) => {
  fileList.value.splice(idx, 1);
  emit('addFileList', fileList.value);
};
</script>
<style lang="scss" scoped>
.wrap_input {
  display: flex;
  align-items: center;
}
.name_input {
  flex-shrink: 0;
  width: 184px;
  font-size: 16px;
  color: #4c4c4c;
}
.mark_essential {
  color: #f8626c;
}
.inner_input {
  box-sizing: border-box;
  margin-left: 16px;
}
.top_input {
  position: relative;
  display: flex;
  align-items: center;
}
input[type='file'] {
  box-sizing: border-box;
  position: absolute;
  width: 1px;
  height: 1px;
  top: 0;
  left: 0;
  overflow: hidden;
}
input[type='file']:focus + label {
  outline: 2px solid #0d5efe;
}
.btn_file {
  position: relative;
  display: inline-block;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  line-height: 46px;
  flex-shrink: 0;
  text-align: center;
  cursor: pointer;
  @include setSize(110px, 46px);
  &:before {
    content: '';
    display: inline-block;
    vertical-align: top;
    @include setSize(16px, 46px);
    @include backgroundImage(
      "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m13.13 7.343-5.545 5.544c-1.066 1.066-2.858 1-4.005-.148-1.148-1.147-1.214-2.94-.149-4.005l6.027-6.027c.665-.665 1.787-.624 2.503.093.717.717.758 1.838.093 2.503l-5.062 5.063c-.266.265-.716.248-1.002-.037-.285-.286-.302-.737-.037-1.002l4.58-4.58-.779-.779-4.58 4.58c-.665.666-.623 1.787.093 2.504.717.716 1.838.758 2.503.092l5.063-5.062c1.065-1.065.999-2.858-.149-4.005C11.537.929 9.744.863 8.68 1.928L2.652 7.955c-1.465 1.466-1.374 3.929.204 5.507 1.579 1.579 4.042 1.67 5.508.204l5.544-5.544-.779-.779z' fill='%234C4C4C'/%3E%3C/svg%3E",
      16px,
      center
    );
  }
}
.info_input {
  padding: 0 0 0 16px;
  font-size: 14px;
  color: #666;
  line-height: 1.42;
}
.filebox {
  margin: 4px 0 0 -8px;
  li {
    display: inline-block;
    margin: 8px 0 0 8px;
    padding: 8.5px 12px 8.5px 16px;
    background-color: #f8f8f8;
    font-size: 15px;
    word-break: break-all;
    .btn_delete {
      @include setSize(16px, 16px);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.092 4.111a.5.5 0 0 0 0 .707L7.273 8l-3.181 3.182a.5.5 0 1 0 .707.707L7.98 8.707l3.182 3.182a.5.5 0 0 0 .707-.707L8.688 8l3.182-3.182a.5.5 0 1 0-.707-.707L7.98 7.293 4.799 4.11a.5.5 0 0 0-.707 0z' fill='%234C4C4C'/%3E%3C/svg%3E");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
@media screen and (max-width: 1023px) {
  .wrap_input {
    display: block;
  }
  .name_input {
    width: 100%;
  }
  .inner_input {
    width: 100%;
    margin-left: 0;
    margin-top: 8px;
  }
  .top_input {
    display: block;
  }
  .btn_file {
    width: 100%;
  }
  .info_input {
    display: inline-block;
    padding: 16px 0 0;
  }
  .filebox {
    margin-top: 12px;
  }
}
</style>
