<template>
  <section class="layer_search">
    <h2 class="screen_out">검색하기</h2>
    <div class="inner_search">
      <div class="head_search">
        <NuxtLink
          to="/"
          class="link_logo"
          @click="refreshCurrPage('/')"
        >
          <img
            src="https://t1.kakaocdn.net/dkt_corp/service/logo_header.svg"
            alt="디케이테크인"
          />
          <span class="screen_out">홈으로 바로가기</span>
        </NuxtLink>
        <div class="wrap_btn">
          <button
            class="btn_close"
            @click="searchLayer.toggleSearchLayer('close')"
          >
            <span class="screen_out">검색레이어 닫기</span>
          </button>
          <ButtonConsult
            v-if="isWidthStore.resolutionList.isPc"
            :logInfo="{ page: 'header', name: '헤더' }"
            @onBeforeOpen="onBeforeOpen"
          />
        </div>
      </div>
      <div class="body_search">
        <div class="area_form">
          <FormTotalSearch :type="'layer'" />
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { useIsWidth, useSearchLayer, useDimmedOpen } from '/stores/store';
const isWidthStore = useIsWidth();
const searchLayer = useSearchLayer();
const dimmedOpen = useDimmedOpen();
const onBeforeOpen = () => {
  searchLayer.toggleSearchLayer('close');
};
watch(
  () => searchLayer.isSearchOpen,
  (isopen) => {
    if (isopen) dimmedOpen.toggleDimmed('open');
    else
      setTimeout(() => {
        dimmedOpen.toggleDimmed('close');
      }, '400');
  },
);
</script>
<style lang="scss" scoped>
.layer_search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100000;
  background-color: #fff;
  transform: translateY(-100%);
  transition: transform 0.4s cubic-bezier(0.59, 0.01, 0.28, 1);
  transition-delay: 0.2s;
  &.open {
    transform: translateY(0);
  }
}
.inner_search {
  box-sizing: border-box;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 128px;
}
.head_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  .link_logo {
    margin-top: 7px;
  }

  .btn_close {
    display: inline-block;
    margin-right: 17px;
    @include setSize(38px, 38px);
    @include closeBtnIcon();
  }
}
.body_search {
  padding: 50px 0 70px;
}
.area_form {
  width: 1040px;
  margin: 0 auto;
}
@media screen and (max-width: 1439px) {
  .inner_search {
    padding: 0 80px;
  }
  .area_form {
    width: 864px;
  }
}
@media screen and (max-width: 1023px) {
  .inner_search {
    box-sizing: border-box;
    max-width: 100%;
    min-width: var(--minWidth);
    padding: 0 20px;
  }
  .head_search {
    height: 60px;
    .link_logo {
      margin-top: 8px;
      img {
        height: 20px;
      }
    }
    .btn_close {
      margin-right: 0;
      @include setSize(30px, 30px);
    }
  }
  .body_search {
    padding: 16px 0 40px;
  }
  .area_form {
    width: 100%;
  }
}
@media screen and (max-width: 280px) {
  .inner_search {
    min-width: 280px;
  }
}
</style>
