<template>
  <div :class="['wrap_input', { type_error: props.isError }]">
    <label :for="id"
      >{{ label
      }}<span
        v-if="isEssential"
        class="mark_essential"
      >
        *<span class="screen_out">필수입력값</span></span
      ></label
    >
    <div class="inner_input">
      <textarea
        :id="id"
        ref="textArea"
        v-model="model"
        :placeholder="props.placeHolder"
        @input="onInputEvent"
        @blur="onBlurEvent"
      ></textarea>
      <span class="info_text">{{ textNum }} /{{ maxlength }}</span>
    </div>
  </div>
  <!-- 사용시
   <TextAreaBasic
      :id="'detailData'"
      v-model="inputValues.detailData.value"
      :label="'내용'"
      :isEssential="true"
      :placeHolder="'문의내용을 입력해주세요.'"
      :isError="inputValues.detailData.isError"
      @onBlurEvent="checkInputValue(inputValues.detailData)"
    />
  -->
</template>
<script setup>
const model = defineModel();
const emit = defineEmits(['update:modelValue', 'onInputEvent', 'onBlurEvent']);
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  isEssential: {
    type: Boolean,
    default: false,
  },
  placeHolder: {
    type: String,
    default: '',
  },
  isError: {
    type: Boolean,
    default: false,
  },
  maxlength: {
    type: Number,
    default: 1000,
  },
});
const textArea = ref();
const textNum = ref(0);
const checkTextNum = () => {
  if (textArea.value.value.length > props.maxlength) {
    alert(`최대 ${props.maxlength}자까지 입력 가능합니다.`);
    textArea.value.value = textArea.value.value.substring(0, props.maxlength);
  }
  textNum.value = textArea.value.value.length;
};
const onInputEvent = (event) => {
  checkTextNum();
  emit('update:modelValue', event.target.value);
  emit('onInputEvent', event);
};
const onBlurEvent = () => {
  emit('onBlurEvent');
};
watch(
  () => model.value,
  () => {
    setTimeout(() => {
      checkTextNum();
    }, 100);
  },
);
</script>
<style lang="scss" scoped>
.wrap_input {
  display: flex;
  align-items: center;
}
label {
  width: 184px;
  font-size: 16px;
  color: #4c4c4c;
}
.mark_essential {
  color: #f8626c;
}
.inner_input {
  position: relative;
  flex-grow: 1;
  box-sizing: border-box;
  width: 360px;
  margin-left: 16px;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
}
textarea {
  box-sizing: border-box;
  width: 100%;
  height: 296px;
  padding: 14px 16px 35px 16px;
  line-height: 20px;
  font-size: 15px;
  letter-spacing: -0.5px;
  resize: none;
  border: none;
  border-radius: 4px;
  outline-offset: 1px;
  &:focus {
    border: none;
    outline: 1px solid #222;
  }
  &::placeholder {
    color: #bbb;
  }
}
.info_text {
  position: absolute;
  left: 16px;
  bottom: 1px;
  width: calc(100% - 32px);
  padding-bottom: 14px;
  text-align: right;
  font-size: 14px;
  color: #787878;
  background: #fff;
}
// 에러스타일
.wrap_input.type_error {
  textarea {
    border: 1px solid #f8626c;
    &:focus {
      border: 1px solid #f8626c;
    }
  }
}

@media screen and (max-width: 1023px) {
  .wrap_input {
    display: block;
  }
  label {
    width: 100%;
  }
  .inner_input {
    width: 100%;
    margin-left: 0;
    margin-top: 8px;
  }
}
</style>
