<template>
  <div class="direct-link">
    <a href="#mainContent">본문 바로가기</a>
    <a href="#gnbContent">메뉴 바로가기</a>
  </div>

  <div
    :style="isWidthStore.isDone ? 'visibility:visible' : 'visibility:hidden'"
    class="container-doc"
  >
    <TheHeader />

    <main class="doc-main">
      <section class="inner-main">
        <NuxtPage />
      </section>
    </main>
    <FooterMo v-if="!isWidthStore.resolutionList.isPc" />
    <FooterPc v-else />
  </div>
  <ButtonConsult
    v-if="!isWidthStore.resolutionList.isPc"
    :type="'float'"
    :logInfo="{ page: 'header', name: '헤더' }"
  />
  <div
    v-if="dimmedOpen.isDimmedOpen"
    class="dimmed"
  ></div>
  <LayerDownload v-if="downloadOpen.isDownloadOpen" />
  <LayerInquiry v-if="consultOpen.isConsultOpen" />
  <LayerInquiryCs v-if="csInqueryOpen.isCsInquiryOpen" />
  <LayerSearch :class="{ open: searchLayer.isSearchOpen }" />
  <LayerShare v-if="shareOpen.isShareOpen" />
  <PopupToast v-if="toast.isToastShow" />
  <ClientOnly>
    <Loading v-if="isLoading" />
  </ClientOnly>
</template>
<script setup>
import {
  useIsWidth,
  useMenuData,
  useSearchLayer,
  useScrollDirection,
  useDimmedOpen,
  useDownloadOpen,
  useConsultOpen,
  useShareOpen,
  useBackStore,
  useCsInquiryOpen,
  useToast,
  useDeviceCheck,
} from '/stores/store';
import { useLoading } from 'daco-common-nuxt3';
const { isDesktop } = useDevice();

const isWidthStore = useIsWidth();
const menuData = useMenuData();
const searchLayer = useSearchLayer();
const scrollDirection = useScrollDirection();
const dimmedOpen = useDimmedOpen();
const downloadOpen = useDownloadOpen();
const consultOpen = useConsultOpen();
const csInqueryOpen = useCsInquiryOpen();
const shareOpen = useShareOpen();
const isBack = useBackStore();
const { isLoading } = useLoading();
const toast = useToast();
const deviceCheck = useDeviceCheck();

deviceCheck.$patch({
  isDesktop: isDesktop,
});

const bodyClass = computed(() => {
  return dimmedOpen.isDimmedOpen ? 'stop_scrolling' : '';
});
const route = useRoute();

if (route.query.layer === 'consult') {
  consultOpen.isConsultOpen = true;
}
if (route.query.layer === 'cs') {
  csInqueryOpen.isCsInquiryOpen = true;
}
useHead({
  bodyAttrs: {
    class: bodyClass,
  },
});

onMounted(() => {
  isWidthStore.setResolution();

  window.addEventListener('resize', () => {
    isWidthStore.setResolution(window.innerWidth);
    menuData.mobileMenuToggle('close');
  });

  let preScrollTop = 0;

  window.addEventListener('scroll', () => {
    const nextScrollTop = window.scrollY;
    if (preScrollTop < 0) {
      preScrollTop = nextScrollTop;
      return;
    }
    const isScrollUp = preScrollTop >= nextScrollTop;
    scrollDirection.$patch({
      isScrollDown: !isScrollUp,
    });
    preScrollTop = nextScrollTop;
  });

  window.onpopstate = () => {
    isBack.setBack(true);
  };
});

watch(
  () => route.fullPath, //내부라우트이동시마다
  () => {
    //초기화 모바일메뉴, 검색레이어
    menuData.mobileMenuToggle('close');
    searchLayer.toggleSearchLayer('close');
  },
);
</script>
