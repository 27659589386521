<template>
  <div
    v-if="
      (isWidthStore.resolutionList.isTablet || isWidthStore.resolutionList.isPc1) &&
      menuData.isMobileOpen
    "
    class="dimmed_gnb"
  ></div>
  <div :class="['area_gnb', { on: menuData.isMobileOpen }]">
    <div class="top_gnb">
      <NuxtLink
        to="/"
        class="link_logo"
        @click="refreshCurrPage('/')"
      >
        <img
          src="https://t1.kakaocdn.net/dkt_corp/service/logo_header.svg"
          alt="디케이테크인"
        />
        <span class="screen_out">홈으로 바로가기</span>
      </NuxtLink>
      <button
        type="button"
        class="btn_close"
        @click="menuData.mobileMenuToggle"
      >
        <span class="screen_out">메뉴닫기</span>
      </button>
    </div>
    <nav
      id="gnbContent"
      class="doc-gnb"
    >
      <h2 class="screen_out">메인메뉴</h2>
      <ul class="list_gnb">
        <li
          v-for="(item, idx) in menuData.menuData"
          :key="`menu_${idx}`"
          :class="[{ type_sub: item.subTabs.length }, { on: item.isSubOpen }]"
        >
          <LinkBasic
            v-if="item.link"
            :text="item.name"
            :link="item.link"
            :isBlank="item.isOutlink"
            :data-tiara-action-name="`헤더_${item.name}_클릭`"
            data-tiara-action-kind="ClickHeader"
          />
          <a
            v-else
            href="javascript:;"
            role="button"
            rel="nosublink"
            :data-tiara-action-name="`헤더_${item.name}_클릭`"
            data-tiara-action-kind="ClickHeader"
            @click="menuData.subMenuToggle(idx)"
            >{{ item.name }}</a
          >
          <div
            v-if="item.subTabs.length"
            class="wrap_sub"
          >
            <template
              v-for="(subItem, subIdx) in item.subTabs"
              :key="`sub_${subIdx}`"
            >
              <div
                v-for="(list, listIdx) in subItem"
                :key="`list_${listIdx}`"
                class="inner_sub"
              >
                <strong v-if="list.title">{{ list.title }}</strong>
                <ul>
                  <li
                    v-for="(link, linkIdx) in list.list"
                    :key="`link_${linkIdx}`"
                  >
                    <LinkBasic
                      :text="link.name"
                      :link="link.link"
                      :isBlank="link.isBlank"
                      :data-tiara-action-name="`헤더_${link.name}_클릭`"
                      data-tiara-action-kind="ClickHeader"
                    />
                  </li>
                </ul>
              </div>
            </template>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script setup>
import { useIsWidth, useMenuData } from '/stores/store';
const isWidthStore = useIsWidth();
const menuData = useMenuData();
const bodyClass = computed(() => {
  return menuData.isMobileOpen ? 'stop_scrolling' : '';
});

useHead({
  bodyAttrs: {
    class: bodyClass,
  },
});
</script>
<style lang="scss" scoped>
.dimmed_gnb {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.area_gnb {
  position: fixed;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  background-color: #fff;
  transform: translateX(100%);
  &.on {
    transform: translateX(0);
    transition: transform 0.4s ease;
  }
}
.top_gnb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 12px 0 20px;
  .link_logo {
    margin-top: 8px;
    img {
      height: 20px;
    }
  }
  .btn_close {
    @include setSize(30px, 30px);
    @include closeBtnIcon();
  }
}
.doc-gnb {
  overflow: auto;
  height: calc(100% - 60px);
}
.list_gnb {
  padding-bottom: 100px;
  & > li {
    overflow: hidden;
    max-height: 60px;
    transition: max-height 0.5s ease;
    & > a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      padding: 0 20px;
      font-size: 18px;
      font-weight: 700;
    }
    &.type_sub {
      & > a:after {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        @include backgroundImage(
          "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23fff' d='M0 0h24v24H0z'/%3E%3Cpath d='m4 9 8 8 8-8' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E",
          24px 24px,
          center
        );
      }
      &.on {
        max-height: 300vh;
        transition: max-height 0.5s ease;
        a {
          &:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23fff' d='M24 24H0V0h24z'/%3E%3Cpath d='m20 15-8-8-8 8' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }
}
.wrap_sub {
  padding: 32px;
  background-color: #f6f6f6;
}
.inner_sub {
  & > strong {
    display: block;
    font-size: 16px;
    margin-bottom: 24px;
  }
  li {
    a {
      display: block;
      font-size: 16px;
    }
    & + li {
      margin-top: 16px;
    }
  }
  & + .inner_sub {
    margin-top: 32px;
  }
}
@media all and (min-width: 812px) and (max-width: 1439px) {
  .area_gnb {
    width: 360px;
  }
}
</style>
