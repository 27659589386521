<template>
  <div class="wrap_layer">
    <div class="layer_inquiry">
      <div class="head_layer">
        <h2>CS 문의</h2>
      </div>
      <div class="body_layer">
        <div class="inner_body">
          <form class="form_download">
            <div class="area_form">
              <div class="item_form">
                <SelectBasicCs
                  :label="'문의분류'"
                  :isEssential="true"
                  :placeHolder="'카테고리를 선택해주세요'"
                  :optList="inquiryOpt"
                  :optType="'kindInquiry'"
                  :isError="inputValues.kindInquiry.isError"
                  @selectItem="selectTypeItem"
                />
              </div>
              <div class="item_form">
                <SelectBasicCs
                  :hasCate="SecondarySelectData.type"
                  label="상세분류"
                  :isEssential="true"
                  :placeHolder="`카테고리를 선택해주세요`"
                  :optList="inputValues.kindInquiry.value ? SecondarySelectData.list : []"
                  :optType="'kindSecondary'"
                  :isError="inputValues.kindSecondary.isError"
                  @selectItem="selectTypeItem"
                />
              </div>
              <div class="item_form">
                <InputBasicCs
                  :id="'title'"
                  v-model="inputValues.title.value"
                  :label="'제목'"
                  :isEssential="true"
                  :maxlength="20"
                  :placeHolder="'제목을 입력해주세요 (20자 이내)'"
                  :isError="inputValues.title.isError"
                  @onInputEvent="checkInputValue(inputValues.title)"
                  @onBlurEvent="checkInputValue(inputValues.title)"
                />
              </div>
              <div class="item_form">
                <TextAreaBasicCs
                  :id="'detailData'"
                  v-model="inputValues.detailData.value"
                  :label="'내용'"
                  :isEssential="true"
                  :placeHolder="'문의내용을 입력해주세요.'"
                  :isError="inputValues.detailData.isError"
                  :maxlength="3000"
                  @onInputEvent="checkInputValue(inputValues.detailData)"
                  @onBlurEvent="checkInputValue(inputValues.detailData)"
                />
              </div>
              <div class="item_form">
                <InputFileCs
                  :id="'addedFile'"
                  :label="'첨부파일'"
                  :isEssential="false"
                  :maxFileSize="10"
                  :acceptInfo="['jpg', 'jpeg', 'png', 'gif', 'pdf']"
                  @addFileList="setFileList"
                />
              </div>
            </div>
            <div class="area_form">
              <div class="item_form">
                <!-- 텍스트 길이 자르기 -->
                <InputBasicCs
                  :id="'name'"
                  v-model="inputValues.name.value"
                  :label="'이름'"
                  :isEssential="changableRequiredValue"
                  :maxlength="6"
                  :placeHolder="'이름을 입력해주세요.'"
                  :isError="inputValues.name.isError && inputValues.name.isRequired"
                  @onInputEvent="valueCheck(inputValues.name, checkValidName)"
                  @onBlurEvent="valueCheck(inputValues.name, checkValidName)"
                />
              </div>
              <div class="item_form">
                <InputBasicCs
                  :id="'phone'"
                  v-model="inputValues.phone.value"
                  :label="'연락처'"
                  :isEssential="true"
                  :maxlength="11"
                  :placeHolder="'‘-’제외하고 숫자만 입력해주세요.'"
                  :isError="inputValues.phone.isError"
                  @onInputEvent="valueCheck(inputValues.phone, checkValidPhone)"
                  @onBlurEvent="valueCheck(inputValues.phone, checkValidPhone)"
                />
              </div>
              <div class="item_form">
                <InputBasicCs
                  :id="'firm'"
                  v-model="inputValues.firm.value"
                  :label="'회사(소속)'"
                  :isEssential="changableRequiredValue"
                  :maxlength="20"
                  :placeHolder="'회사(소속)을 입력해주세요.'"
                  :isError="inputValues.firm.isError && inputValues.firm.isRequired"
                  @onInputEvent="valueCheck(inputValues.firm, checkValidAboutFirm)"
                  @onBlurEvent="valueCheck(inputValues.firm, checkValidAboutFirm)"
                />
              </div>
              <div class="item_form">
                <InputBasicCs
                  :id="'jobRole'"
                  v-model="inputValues.jobRole.value"
                  :label="'직급'"
                  :isEssential="changableRequiredValue"
                  :maxlength="20"
                  :placeHolder="'직급을 입력해주세요.'"
                  :isError="inputValues.jobRole.isError && inputValues.jobRole.isRequired"
                  @onInputEvent="valueCheck(inputValues.jobRole, checkValidAboutFirm)"
                  @onBlurEvent="valueCheck(inputValues.jobRole, checkValidAboutFirm)"
                />
              </div>
              <div class="item_form">
                <InputBasicCs
                  :id="'email'"
                  v-model="inputValues.email.value"
                  :label="'이메일'"
                  :isEssential="true"
                  :placeHolder="'이메일을 입력해주세요.'"
                  :isError="inputValues.email.isError"
                  @onInputEvent="valueCheck(inputValues.email, checkValidEmail)"
                  @onBlurEvent="valueCheck(inputValues.email, checkValidEmail)"
                />
              </div>
            </div>
          </form>
          <!-- 에러 노출 케이스 분리 검토 -->
          <div class="area_error">
            <span v-if="inputValues.kindInquiry.isError">· 문의분류가 정확한지 확인해 주세요.</span>
            <span v-if="inputValues.title.isError">· 제목이 정확한지 확인해 주세요.</span>
            <span v-if="inputValues.detailData.isError">· 문의내용이 정확한지 확인해 주세요.</span>
            <span v-if="inputValues.name.isError && inputValues.name.isRequired"
              >· 이름이 정확한지 확인해 주세요.</span
            >
            <span v-if="inputValues.phone.isError">· 연락처가 정확한지 확인해 주세요.</span>
            <span v-if="inputValues.firm.isError && inputValues.firm.isRequired"
              >· 회사(소속)이 정확한지 확인해 주세요.</span
            >
            <span v-if="inputValues.jobRole.isError && inputValues.jobRole.isRequired"
              >· 직급이 정확한지 확인해 주세요.</span
            >
            <span v-if="inputValues.email.isError">· 이메일이 정확한지 확인해 주세요.</span>
          </div>
          <div class="area_agree">
            <div
              v-if="privacyAgreeText1"
              class="box_agree"
            >
              <CheckboxBasicCs
                :id="'chkAgree1'"
                v-model="inputValues.chkAgree1.value"
                :title="'개인정보 수집·이용 동의 (필수)'"
                :style="'black'"
              />
              <p
                class="txt_agree"
                v-html="privacyAgreeText1"
              ></p>
            </div>
            <div
              v-if="privacyAgreeText2"
              class="box_agree"
            >
              <CheckboxBasicCs
                :id="'chkAgree2'"
                v-model="inputValues.chkAgree2.value"
                :title="'개인정보 수집·이용 동의 (선택)'"
                :style="'black'"
              />
              <p
                class="txt_agree"
                v-html="privacyAgreeText2"
              ></p>
            </div>
            <div
              v-if="privacyAgreeText1 || privacyAgreeText2"
              class="box_agree"
            >
              <CheckboxBasicCs
                :id="'chkAgree3'"
                v-model="inputValues.chkAgree3.value"
                :title="'마케팅 활용 및 수신 동의(선택)'"
                :style="'black'"
              />
              <p class="txt_agree">
                회사 및 회사의 서비스와 관련된
                <strong>광고 및 정보 제공(이벤트, 서비스 안내, 생활 정보 등)</strong> 을 위하여
                이름, 연락처, 이메일을 수집합니다. 수집된 개인정보는 <strong>5년간</strong> 보관 후
                파기됩니다. 위 동의를 거부할 권리가 있으며, 동의를 거부하실 경우 광고 및 정보 제공이
                제한됩니다. 더 자세한 내용에 대해서는
                <LinkBasic
                  text="개인정보처리방침"
                  link="https://policy.dktechin.com/privacy-policy"
                  :isBlank="true"
                />을 참고하시기 바랍니다.
              </p>
            </div>
          </div>
          <div class="wrap_btn">
            <ButtonPopupCs
              :nameBtn="'문의하기'"
              :style="'type_black'"
              :isDisabled="isFormFilled"
              @onClickEvnet="submitInquiry"
            />
          </div>
        </div>
      </div>
      <button
        type="button"
        class="btn_close"
        @click="csInqueryOpen.toggleCsInquiryLayer('close')"
      >
        <span class="screen_out">팝업 닫기</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { fetchCategoryCs, fetchTemplateCs, createInquiryCs } from '/api/cs';
import { useDimmedOpen, useSearchLayer, useCsInquiryOpen } from '/stores/store';
import { useApi } from 'daco-common-nuxt3';
const dimmedOpen = useDimmedOpen();
const csInqueryOpen = useCsInquiryOpen();
const searchLayer = useSearchLayer();

const privacyAgreeText1 = ref('');
const privacyAgreeText2 = ref('');
const secondaryCateKey = ref('');
const changableRequiredValue = computed(() => {
  const requiredCateKeyList = [52, 53, 54]; //카카오워크 영업제휴항목들만 이름, 소속, 직책 필수로 변경
  return requiredCateKeyList.includes(secondaryCateKey.value);
});
const baseData = {
  title: {
    isRequired: true,
    value: '',
    isError: false,
  },
  name: {
    isRequired: changableRequiredValue.value,
    value: '',
    isError: false,
  },
  phone: {
    isRequired: true,
    value: '',
    isError: false,
  },
  firm: {
    isRequired: changableRequiredValue.value,
    value: '',
    isError: false,
  },
  jobRole: {
    isRequired: changableRequiredValue.value,
    value: '',
    isError: false,
  },
  email: {
    isRequired: true,
    value: '',
    isError: false,
  },
  kindInquiry: {
    isRequired: true,
    value: '',
    isError: false,
  },
  kindSecondary: {
    isRequired: true,
    value: null,
    isError: false,
  },
  detailData: {
    isRequired: true,
    value: '',
    isError: false,
  },
  addedFile: {
    isRequired: false,
    value: [],
  },
  chkAgree1: {
    isRequired: true,
    value: false,
    isError: false,
  },
  chkAgree2: {
    isRequired: false,
    value: false,
  },
  chkAgree3: {
    isRequired: false,
    value: false,
  },
};

// 인풋값
const inputValues = ref(copyObject(baseData));

const checkInputValue = (property) => {
  //blur시 값 없는지 여부 확인해서 빨갛게 바꾸기
  property.isError = !property.value;
};

const valueCheck = (item, valueFn) => {
  if (item.isRequired) {
    item.isError = !valueFn(item.value);
  }
};

const setInquiryTemplate = (key) => {
  if (!key) {
    inputValues.value.detailData.value = '';
  } else {
    useApi(
      'csTemplate',
      () => {
        return fetchTemplateCs(key).then(
          ({ formContent, privacyAgreementRequired, privacyAgreementOptional }) => {
            inputValues.value.detailData.value = formContent || '';
            privacyAgreeText1.value = privacyAgreementRequired;
            privacyAgreeText2.value = privacyAgreementOptional;
          },
        );
      },
      {},
    );
  }
};

// 클라이언트 사이드에서 처리해도 무방해 보임
// 같은 api를 호출하는 형태, api 인터페이스를 바꾸면 1번의 호출/내부 필터링으로 사용할 수 있음
const { data: categoryData } = await useAsyncData('csCategory', () => fetchCategoryCs());

const makeSecondCate = (categoryId) => {
  const findId = (ele) => {
    return ele.categoryId === categoryId;
  };
  return categoryData.value[0].csCategory.find(findId).csCategory;
};

const madeOptList = (list) => {
  return list.map(({ categoryName, categoryId }) => ({ name: categoryName, key: categoryId }));
};

const madeSelectedData = (optList = [], selectedData = {}, comparisonKey = 'id') => {
  return optList.map((opt) => ({
    ...opt,
    isSelected: selectedData && opt[comparisonKey] === selectedData[comparisonKey],
  }));
};

const inquiryOpt = computed(() => {
  return madeSelectedData(
    madeOptList(categoryData.value[0].csCategory),
    inputValues.value.kindInquiry.value,
    'key',
  );
});

const SecondarySelectData = computed(() => {
  let list = [];
  let type = false;
  const madeSecondCate = makeSecondCate(inputValues.value.kindInquiry.value.key || 2);
  if (madeSecondCate[0].csCategory) {
    //2뎁스인경우
    list = madeSecondCate.map((item) => {
      return {
        name: item.categoryName,
        key: item.categoryId,
        serviceCategory: madeSelectedData(
          madeOptList(item.csCategory),
          inputValues.value.kindSecondary.value,
          'key',
        ),
      };
    });
    type = true;
  } else {
    //1뎁스인경우
    list = madeSelectedData(
      madeOptList(madeSecondCate),
      inputValues.value.kindSecondary.value,
      'key',
    );
    type = false;
  }
  return { type, list };
});

const selectTypeItem = (valueName, value) => {
  //셀렉트 아이템 선택
  if (valueName === 'kindInquiry') {
    inputValues.value.kindSecondary.value = null;
    //문의분류 재선택시 수집동의 텍스트 값 초기화
    privacyAgreeText1.value = '';
    privacyAgreeText2.value = '';
  }
  inputValues.value[valueName].value = value;
  checkInputValue(inputValues.value[valueName]);
  if (valueName === 'kindSecondary') {
    secondaryCateKey.value = value.key; //필수선택 변경항목 적용

    inputValues.value.name.isRequired = changableRequiredValue.value;
    inputValues.value.firm.isRequired = changableRequiredValue.value;
    inputValues.value.jobRole.isRequired = changableRequiredValue.value;
    setInquiryTemplate(value.key);
  } else {
    setInquiryTemplate();
  }
};

const isFormFilled = computed(() => {
  //양식채워졌는지 여부 확인값
  return Object.keys(inputValues.value).some((itemKey) => {
    const formItem = inputValues.value[itemKey];
    return formItem.isRequired && (!formItem.value || formItem.isError);
  });
});

const setFileList = (files) => {
  inputValues.value.addedFile.value = files;
};

// 초기화 기능
const initData = () => {
  inputValues.value = copyObject(baseData);
};

const submitInquiry = async () => {
  // 파일 업로드 테스트 할 것
  const formData = new FormData();
  const {
    name,
    phone,
    firm,
    jobRole,
    email,
    kindSecondary,
    detailData,
    addedFile,
    chkAgree1,
    chkAgree2,
    chkAgree3,
  } = inputValues.value;
  const jsonData = {
    categoryId: kindSecondary.value.key,
    inquiryTitle: title.value,
    inquiryContent: detailData.value,
    privacyInfoName: name.value,
    privacyInfoContact: phone.value,
    privacyInfoCompany: firm.value,
    privacyInfoEmail: email.value,
    privacyInfoPosition: jobRole.value,
    acceptPrivacyPolicy: chkAgree1.value,
    acceptOptionalPrivacyPolicy: chkAgree2.value,
    acceptMarketingPolicy: chkAgree3.value,
  };

  // // jsonData 값 세팅
  formData.append(
    'inquiryRequest',
    new Blob([JSON.stringify(jsonData)], { type: 'application/json' }),
  );
  // fileData 값 세팅
  addedFile.value.forEach((file) => {
    formData.append('multipartFileList', file);
  });

  useApi(
    'createInquiryCs',
    () => {
      //조건검색시
      return createInquiryCs(formData).then((res) => {
        if (res) {
          alert('문의하기 완료\n빠른 시일 내에 답변 드리겠습니다.');
          inquiryConfirmToast(res.acceptMarketingPolicy, res.acceptDate);
          initData();
          csInqueryOpen.toggleCsInquiryLayer('close');
        }
      });
    },
    {},
  );
};

onMounted(async () => {
  searchLayer.toggleSearchLayer('close');
  setTimeout(() => {
    dimmedOpen.toggleDimmed('open');
  }, 1);
});

onUnmounted(() => {
  dimmedOpen.toggleDimmed('close');
});
</script>
<style lang="scss" scoped>
// 스크롤바 커스터마이징
.body_layer::-webkit-scrollbar {
  width: 4px;
}
.body_layer::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}
.body_layer::-webkit-scrollbar-track {
  background: #fff;
}

.wrap_layer {
  display: flex;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
}

.layer_inquiry {
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  max-width: 1120px;
  width: 58%;
  max-height: 80%;
  background-color: #fff;
  padding: 40px;
  border-radius: 4px;
}
.head_layer {
  padding-bottom: 12px;
  h2 {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.5px;
  }
}
.body_layer {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 48px);
}
.area_form {
  border-top: 1px solid #222;
  & + .area_form {
    margin-top: 40px;
  }
}
.item_title {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: 74px;
  padding: 12px 40px;
  border-bottom: 1px solid #e1e1e1;
  em {
    width: 200px;
    color: #4c4c4c;
  }
  em,
  span {
    font-size: 16px;
  }
}
.item_form {
  box-sizing: border-box;
  min-height: 74px;
  padding: 12px 40px;
  border-bottom: 1px solid #e1e1e1;
}

.area_agree {
  margin-top: 40px;
}
.box_agree {
  padding: 32px;
  border-radius: 10px;
  background-color: #f5f5f5;
  & + .box_agree {
    margin-top: 16px;
  }
  .txt_agree {
    margin-top: 12px;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: #555;
    a {
      text-decoration: underline;
      font-weight: bold;
    }
  }
}
.btn_close {
  position: absolute;
  top: 24px;
  right: 24px;
  @include setSize(24px, 24px);
  @include closeBtnIcon();
}
.wrap_btn {
  display: flex;
  justify-content: center;
  margin: 24px -4px 0;
}
.area_error {
  margin-top: 8px;
  font-size: 13px;
  color: #f8626c;
  span {
    display: block;
    line-height: 1.46;
    margin-top: 4px;
  }
}
@media screen and (max-width: 1023px) {
  .wrap_layer {
    align-items: end;
  }
  .layer_inquiry {
    width: 100%;
    max-width: 100%;
    top: auto;
    bottom: 0;
    left: 0;
    transform: translate(0, 0);
    padding: 0;
    border-radius: 8px 8px 0 0;
  }
  .head_layer {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    padding-bottom: 0;
    padding: 16px 20px;
    h2 {
      font-size: 16px;
      font-weight: 700;
    }
  }
  .body_layer {
    overflow-y: auto;
    height: 100%;
    padding: 20px 20px 104px 20px;
  }
  .area_form {
    padding-bottom: 24px;
    border-bottom: 1px solid #f0f0f0;
    border-top: none;
  }
  .item_title {
    display: block;
    padding: 16px;
    border-bottom: none;
    border-radius: 10px;
    background-color: #f5f5f5;
    em {
      display: block;
      width: 100%;
      margin-bottom: 8px;
      font-weight: 500;
      color: #1a1a1a;
    }
    span {
      font-weight: 400;
      color: #666;
      strong {
        font-weight: 400;
      }
    }
    em,
    span {
      font-size: 14px;
    }
  }
  .item_form {
    min-height: auto;
    margin-top: 24px;
    padding: 0;
    border-bottom: none;
  }
  .area_agree {
    margin-top: 24px;
  }
  .btn_close {
    top: 16px;
    right: 16px;
  }
  .wrap_btn {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
  }
}
</style>
