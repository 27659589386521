<template>
  <div :class="['wrap_input', { type_error: props.isError }]">
    <label :for="props.id"
      >{{ props.label
      }}<span
        v-if="props.isEssential"
        class="mark_essential"
      >
        *<span class="screen_out">필수입력값</span></span
      ></label
    >
    <div class="inner_input">
      <input
        :id="props.id"
        v-model="model"
        type="text"
        :maxlength="props.maxlength"
        :placeholder="props.placeHolder"
        @input="onInputEvent"
        @blur="onBlurEvent"
      />
    </div>
  </div>
  <!-- 사용시
   <InputBasic
      :id="'name'"
      v-model="inputValues.name.value"
      :label="'이름'"
      :isEssential="true"
      :maxlength="6"
      :placeHolder="'이름을 입력해주세요.'"
      :isError="inputValues.name.isError"
      @onBlurEvent="nameCheck(inputValues.name.value)"
    />
  -->
</template>
<script setup>
const model = defineModel();
const emit = defineEmits(['update:modelValue', 'onInputEvent', 'onBlurEvent']);
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  isEssential: {
    type: Boolean,
    default: false,
  },
  maxlength: {
    type: Number,
    default: 100,
  },
  placeHolder: {
    type: String,
    default: '',
  },
  isError: {
    type: Boolean,
    default: false,
  },
});
const onInputEvent = (event) => {
  if (props.maxlength > 0) {
    // 한글 작성 시 버그로 인한 코드
    const segmentInp = [...new Intl.Segmenter().segment(event.target.value)];
    if (segmentInp.length > props.maxlength) {
      const limitedInp = segmentInp
        .slice(0, props.maxlength)
        .map((item) => item.segment)
        .join('');
      event.target.value = limitedInp;
    }
  }
  emit('update:modelValue', event.target.value);
  emit('onInputEvent', event);
};
const onBlurEvent = () => {
  emit('onBlurEvent');
};
</script>
<style lang="scss" scoped>
.wrap_input {
  display: flex;
  align-items: center;
}
label {
  width: 184px;
  font-size: 16px;
  color: #4c4c4c;
}
.mark_essential {
  color: #f8626c;
}
.inner_input {
  width: 360px;
  margin-left: 16px;
}
input {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 14px 16px;
  line-height: 20px;
  font-size: 15px;
  letter-spacing: -0.5px;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
  &:focus {
    border-color: #222;
  }
  &::placeholder {
    color: #bbb;
  }
}
// 에러스타일
.wrap_input.type_error {
  input {
    border: 1px solid #f8626c;
    &:focus {
      border-color: #222;
    }
  }
}

@media screen and (max-width: 1023px) {
  .wrap_input {
    display: block;
  }
  label {
    width: 100%;
  }
  .inner_input {
    width: 100%;
    margin: 8px 0 0 0;
  }
}
</style>
